const palettes = {
  main: '#3861fb',
  mainDeep: '#3257e2',
  danger: '#EA3943',
  dangerDeep: '#d3333c',
  success: '#0DB173',
  warning: '#e48023',
  successNotify: '#e6f7f0',
  dangerNotify: '#fcebec',
  dark1: '#222531',
  dark2: '#58667E',
  dark3: '#A6B0C3',
  dark4: '#CFD6E4',
  dark5: '#EFF2F5',
  dark6: '#FBFCFE',
  tableRowHover: '#f7f9ff',
  baseBackground: '#ffffff',
  alternateTextColor: '#ffffff',
}
const palettesAlpha = {
  main10: palettes.main + '1a',
  main50: palettes.main + '80',
  danger10: palettes.danger + '1a',
  success10: palettes.success + '1a',
  warning10: palettes.warning + '1a',
}

module.exports = { palettes: {
    ...palettes,
    ...palettesAlpha
  }
}
