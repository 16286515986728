require('./bootstrap');
import Vue from 'vue'

// App modules
import App from './App.vue'
import api from './api';
import router from './router';
import store from './store';

// Init functions
import LibsSetup from './setup/libs';
import FiltersSetup from './setup/filters';
import PluginsSetup from './setup/plugins';
import ComponentsSetup from './setup/components';
import NavigationGuardsSetup from './setup/navigationGuard';
import EventBusSetup from './setup/eventBus';

// Services
import ThemesService from './services/themes';

Vue.use(ThemesService);

LibsSetup(Vue);
PluginsSetup(Vue);
ComponentsSetup(Vue);
FiltersSetup(Vue);
EventBusSetup(Vue);

NavigationGuardsSetup();

const apiConfig = {
  store,
  router,
};

Vue.use(api, apiConfig);

const app = new Vue({
  el: '#app',
  router,
  store,
  components: { App }
});

export default app;