<template>
  <Layout>
    <BrokerAccount
      ref="brokerAccount"
      :initial-data="currentBrokerAccount"
      @confirm="getBrokersAccounts"
    />
    <ChangePassword
      ref="changePassword"
    />
    <SetupTfa
      ref="setupTfa"
      @success="successEnableTfa"
    />
    <BackupCodes
      :codes.sync="codes"
      ref="backupCodes"
    />
    <TfaPopup
      ref="tfaPopup"
      @success="openBackupCodes"
    />
    <DisableTfa
      ref="disabledTfa"
    />
    <DeletePopup
      popup-title="Delete Broker Account"
      ref="deletePopup"
      @confirm="deleteAccount"
    />
    <UiTitle title-text="Account Settings"/>
    <div class="profile ui-d-flex ui-g-w-md ui-ai-start">
      <UiCard
        class="ui-6 ui-g-md ui-d-block"
        indent="big"
      >
        <UiTitle title-text="Profile">
          <UiIcon
            slot="ui-title__add"
            :size="20"
            :color="$theme.cp.dark3"
            name="user"
          />
        </UiTitle>
        <UiInput
          v-model="userName"
          class="ui-m-xl-b"
          placeholder="Your name"
          label="Name"
          :error="errors.name"
        />
        <UiButton
          :filled="true"
          size="medium"
          substyle="fas"
          icon="check"
          @click="saveProfile"
        >
          Save
        </UiButton>
        <UiSeparator class="ui-m-xxl-t ui-m-xxl-b" />
        <UiTitle
          title-text="Password"
          text="Protect your personal account with complex password"
        >
          <UiIcon
            slot="ui-title__add"
            :size="20"
            :color="$theme.cp.dark3"
            name="key"
          />
        </UiTitle>
        <UiButton
          size="medium"
          substyle="fas"
          icon="key"
          @click="openChangePassword"
        >
          Change Password
        </UiButton>
        <UiSeparator class="ui-m-xxl-t ui-m-xxl-b" />
        <UiTitle
          title-text="Two-factor Authentication"
          text="Add an extra layer of security for your account"
        >
          <div
            slot="ui-title__add"
            class="ui-d-flex ui-ai-center"
          >
            <div
              class="tfa-status ui-m-sm-r"
              :class="[
                {
                  'tfa-status_enabled': isAvailableTFA
                },
              ]"
            >
              {{ isAvailableTFA ? 'Enabled' : 'Disabled' }}
            </div>
            <UiIcon
              :size="20"
              :color="$theme.cp.dark3"
              name="lock"
            />
          </div>
        </UiTitle>
        <UiButton
          v-if="!isAvailableTFA"
          size="medium"
          substyle="fas"
          icon="lock"
          @click="setupTfa"
        >
          Setup 2FA
        </UiButton>
        <UiButton
          v-if="isAvailableTFA"
          class="ui-m-sm-r"
          size="medium"
          substyle="fas"
          icon="clipboard-list-check"
          @click="openTfaPopup"
        >
          Backup Codes
        </UiButton>
        <UiButton
          v-if="isAvailableTFA"
          type="danger"
          size="medium"
          substyle="fas"
          icon="times"
          @click="disabledTfa"
        >
          Turn Off
        </UiButton>
      </UiCard>
      <UiCard
        class="ui-6 ui-g-md ui-d-block"
        indent="big"
      >
        <UiTitle
          title-text="Broker Accounts"
          text="Your information about your accounts at different brokers"
        >
          <UiIcon
            slot="ui-title__add"
            :size="20"
            :color="$theme.cp.dark3"
            name="landmark"
          />
        </UiTitle>
        <UiButton
          size="medium"
          substyle="fas"
          icon="plus"
          @click="openBrokerAccount"
        >
          Add Broker Account
        </UiButton>
        <div
          v-for="account in brokersAccountsList"
          :key="account.id"
          class="broker-account"
        >
          <UiSeparator class="ui-m-lg-b ui-m-lg-t"/>
          <div class="broker-account__row">
            <div class="broker-account__content">
              <div class="broker-account__icon ui-m-sm-r">
                <img :src="account.image" alt="">
              </div>
              <div class="broker-account__info">
                <div class="broker-account__broker">{{ account.broker_name }}</div>
                <div class="broker-account__name">{{ account.name }}</div>
              </div>
            </div>
            <div class="broker-account__actions ui-d-flex ui-ai-center">
              <UiActionIcon
                tooltip="Edit"
              >
                <span class="ui-m-sm-r" @click="editBrokerAccount(account)">
                  <UiIcon
                    :cursor="true"
                    :size="14"
                    :color="$theme.cp.main"
                    name="pen"
                  />
                </span>
              </UiActionIcon>
              <UiActionIcon
                tooltip="Delete"
              >
                <span @click="openDeletePopup(account)">
                  <UiIcon
                    :cursor="true"
                    :size="14"
                    :color="$theme.cp.danger"
                    name="trash"
                  />
                  </span>
              </UiActionIcon>
            </div>
          </div>
        </div>
      </UiCard>
    </div>
  </Layout>
</template>

<script>
import {mapGetters} from 'vuex';
import Layout from '@Components/Layout';
import ChangePassword from '@Popups/ChangePassword';
import SetupTfa from '@Popups/tfa/Setup';
import BackupCodes from '@Popups/tfa/BackupCodes';
import TfaPopup from '@Popups/tfa/TfaPopup';
import DisableTfa from '@Popups/tfa/Disable';
import DeletePopup from '@Popups/DeletePopup';
import BrokerAccount from '@Popups/BrokerAccount';

export default {
  name: 'Profile',

  components: {
    Layout,
    SetupTfa,
    BackupCodes,
    TfaPopup,
    DisableTfa,
    ChangePassword,
    BrokerAccount,
    DeletePopup,
  },

  data() {
    return {
      name: '',
      codes: [],
      currentBrokerAccount: undefined,
      errors: {},
      notifyData: {
        type: 'success',
        text: '',
      }
    };
  },

  computed: {
    ...mapGetters({
      profile: 'auth/profile',
      brokersList: 'brokers/brokersList',
      brokersAccountsList: 'brokers/brokersAccountsList',
    }),

    isAvailableTFA() {
      return this.profile.two_factor;
    },

    userName: {
      get() {
        return this.profile ? this.profile.name : '';
      },
      set(newVal) {
        this.name = newVal;
      },
    },
  },

  created () {
    this.$store.dispatch('auth/getProfile');
  },

  methods: {
    setupTfa() {
      this.$refs.setupTfa.open();
    },
    disabledTfa() {
      this.$refs.disabledTfa.open();
    },
    openTfaPopup() {
      this.$refs.tfaPopup.open();
    },

    successEnableTfa(codes) {
      this.$eventBus.$emit('notify-event', {type: 'success', text: 'Two-factor Authentication Enabled'});
      this.openBackupCodes(codes);
    },

    openBackupCodes(codes) {
      this.codes = codes;
      this.$refs.backupCodes.open();
    },
    openChangePassword() {
      this.$refs.changePassword.open();
    },
    openBrokerAccount() {
      this.currentBrokerAccount = undefined;
      this.$refs.brokerAccount.open();
    },
    editBrokerAccount(account) {
      this.currentBrokerAccount = account;
      this.$refs.brokerAccount.open();
    },
    openDeletePopup(account) {
      this.$refs.deletePopup.open(account);
    },
    getBrokersAccounts() {
      this.$store.dispatch('brokers/getBrokersAccounts');
    },

    deleteAccount(id) {
      this.$api.deleteBrokersAccount(id).then(() => {
        this.getBrokersAccounts();
        this.notifyData.text = 'Broker Account Deleted';
        this.$eventBus.$emit('notify-event', this.notifyData);
      });
    },

    saveProfile() {
      this.errors = {};
      this.$api.putProfile({name: this.userName}).then((response) => {
        this.$store.commit('auth/setUserInfo', response.data.payload.user);
        this.notifyData.text = 'Name Changed';
        this.$eventBus.$emit('notify-event', this.notifyData);
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      });
    },
  },

};
</script>

<style lang="scss">
  .tfa-status{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--color-danger);
    &_enabled{
      color: var(--color-success);
    }
  }
  .broker-account{
    &__row{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__content{
      display: flex;
      align-items: center;
    }

    &__icon{
      width: 32px;
      height: 32px;
    }

    &__broker{
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      color: var(--color-dark2);
    }
    &__name{
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      color: var(--color-dark1);
    }
  }
</style>
