<template>
  <UiPopup
    popup-icon="clipboard-list-check"
    popup-title="Backup Codes"
    popup-subtitle="Copy backup codes so you are able to restore access if you lost your phones"
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
  >
    <div
      slot="ui-popup__body"
      class="backup-codes ui-m-lg-b"
    >
      <div class="backup-codes__wrapper ui-d-flex ui-fw-wrap">
        <div
            v-for="(code, id) in codes"
            :key="id"
            class="backup-codes__item ui-6"
        >
          {{ code }}
        </div>
      </div>
      <UiButton
        class="ui-m-lg-t ui-m-sm-r"
        size="medium"
        substyle="fas"
        icon="copy"
        @click="copy"
      >
        Copy
      </UiButton>
      <UiButton
        class="ui-m-lg-t"
        size="medium"
        substyle="fas"
        icon="sync-alt"
        @click="generateNewCodes"
      >
        Generate Codes
      </UiButton>
    </div>
    <UiButton
      slot="ui-popup__footer"
      :filled="true"
      size="big"
      substyle="fas"
      icon="check"
      @click="close"
    >
      Got It
    </UiButton>
  </UiPopup>
</template>

<script>
import UiCodeInput from '@UiElements/UiCodeInput';
import AppleImg from "@/images/app/app-store.svg"
import GoogleImg from "@/images/app/google-play.svg"

export default {
  name: 'BackupCodes',

  components: {
    UiCodeInput,
  },

  props: {
    codes: {
      type: Array,
      default () {
        return [];
      },
    },
  },

  data() {
    return {
      isLoading: false,
      isVisible: false,
    }
  },

  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    copy() {
      const formattedCodes = this.$_.join(this.codes, '\n');
      this.$copyText(formattedCodes);
    },
    generateNewCodes() {
      this.isLoading = true;
      this.$api.postRecoveryCodes().then((response) => {
        this.$emit('update:codes', response.data.payload.codes);
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
  .backup-codes__item{
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: var(--color-dark1);
  }
</style>
