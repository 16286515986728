import Axios from 'axios';
import QS from 'qs';

export default {
  csoApi(baseUrl) {
    return Axios.create({
      baseURL: baseUrl,
      withCredentials: true,
      headers: {
        common: {
          Authorization: `Bearer ${localStorage.getItem('auth-token')}` || '',
        },
      },
      paramsSerializer(params) {
        return QS.stringify(params, { arrayFormat: 'repeat' });
      },
      timeout: 50000,
    });
  },
};
