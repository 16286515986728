import auth from './modules/auth';
import user from './modules/user';
import tfa from './modules/2fa';
import brokers from './modules/brokers';
import portfolios from './modules/portfolios';

export default class Requests {
  constructor(csoApi) {
    Object.assign(this, { csoApi });

    const requests = {
      ...auth,
      ...user,
      ...tfa,
      ...brokers,
      ...portfolios,
    };

    Object.assign(this, requests);
  }
}
