<template>
  <div class="view">
    <div class="view__wrapper">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Layout',

  components: {

  },

  methods: {

  },
};
</script>

<style lang="scss">
  .view{
    overflow-y: auto;
    min-height: calc(100% - 64px);
    position: relative;
    .el-loading-spinner .path{
      stroke: var(--color-main);
    }
    &__wrapper{
      margin: 0 auto;
      padding: 24px 32px;
      flex-grow: 1;
    }
  }
</style>
