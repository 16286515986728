export default {
  getPortfolio() {
    return this.csoApi.get('portfolio');
  },
  postPortfolio(payload) {
    return this.csoApi.post('portfolio', payload);
  },
  putPortfolio(id, payload) {
    return this.csoApi.put(`portfolio/${id}`, payload);
  },
  putPortfolioAccounts(id, payload) {
    return this.csoApi.put(`/portfolio/${id}/accounts`, payload);
  },
  deletePortfolio(id) {
    return this.csoApi.delete(`portfolio/${id}`);
  },
  getPortfolioAssets(id, params) {
    return this.csoApi.get(`portfolio/${id}/assets`, {params});
  },
};
