import Connectors from './connectors';
import Interceptors from './interceptors';
import Requests from './requests';

export default {
  install(Vue, config) {
    const { csoApi } = Connectors;

    const csoApiInstance = csoApi('/api');

    csoApiInstance.interceptors.response.use(Interceptors.responseSuccessInterceptor, Interceptors.responseErrorInterceptor);

    if (!Vue.prototype.$api) {
      Object.defineProperty(Vue.prototype, '$api', {
        value: new Requests(csoApiInstance),
        writable: false,
      });
    }
    if (!config.store.$api) {
      Object.defineProperty(config.store, '$api', {
        value: Vue.prototype.$api,
        writable: false,
      });
    }
  },
};
