<template>
  <Layout v-if="renderPage">
    <DeletePopup
      popup-title="Delete Portfolio"
      ref="deletePopup"
      @confirm="deletePortfolio"
    />
    <PortfolioPopup
      ref="portfolioPopup"
      :initial-data="currentPortfolio"
      @confirm="getPortfolios"
    />
    <AddTrades
      ref="addTrades"
      :initial-data="currentPortfolio"
      @confirm="getPortfolios"
    />
    <UiTitle title-text="My Portfolios">
      <UiButton
        slot="ui-title__add"
        :filled="true"
        size="medium"
        substyle="fas"
        icon="plus"
        @click="addPortfolio"
      >
        Add Portfolio
      </UiButton>
    </UiTitle>
    <div class="my-portfolios ui-d-flex ui-g-w-md ui-fw-wrap">
      <div
        v-for="portfolio in portfoliosList"
        :key="portfolio.id"
        class="portfolio ui-3 ui-p-lg-l ui-p-lg-t"
        @click="getLink($event, portfolio.id)"
      >
        <UiCard indent="medium">
          <div class="portfolio__info ui-d-flex ui-jc-space-between ui-ai-center">
            <div class="portfolio__time">Created: {{ portfolio.created_at * 1000 | formatDate }}</div>
            <UiGroupActions
              ref="actionGroup"
              :initial-data="portfolio"
              :actions="actions"
            />
          </div>
          <div class="portfolio__brokers ui-m-sm-b">
            <div
              v-if="accountsCount(portfolio.accounts) === 0"
              class="broker broker__no-accounts"
            >
              <div class="broker__name">No Accounts</div>
            </div>
            <div
              v-if="accountsCount(portfolio.accounts)  === 1"
              class="broker"
            >
              <div class="broker__ico">
                <img
                  v-if="getImgUrl(portfolio.accounts[0].account_id)"
                  :src="getImgUrl(portfolio.accounts[0].account_id)" >
              </div>
              <div class="broker__name">{{ portfolio.accounts[0].name}}</div>
            </div>
            <el-popover
              placement="bottom-start"
              trigger="click"
              popper-class="broker__accounts-list"
              @click.native="customPopoverClick"
            >
              <div
                v-if="accountsCount(portfolio.accounts) > 1"
                slot="reference"
                class="broker"
              >
                <div class="broker__ico-wrapper">
                  <div
                    v-for="item in accountsPick(portfolio.accounts)"
                    :key="item"
                    class="broker__ico"
                  >
                    <img :src="getImgUrl(item)" >
                  </div>
                </div>
                <div class="broker__name">{{ accountsCount(portfolio.accounts) }} Accounts</div>
              </div>
              <div
                v-for="account in portfolio.accounts"
                :key="account.id"
                class="broker broker_popower"
              >
                <div class="broker__ico">
                  <img
                    v-if="getImgUrl(account.account_id)"
                    :src="getImgUrl(account.account_id)" >
                </div>
                <div class="broker__name">{{ account.name}}</div>
              </div>
            </el-popover>
          </div>
          <div class="portfolio__title ui-m-sm-b">{{ portfolio.name }}</div>
          <div class="portfolio__summ">≈${{ portfolio.price | formatMoney }}</div>
        </UiCard>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@Components/Layout';
import PortfolioPopup from '@Popups/PortfolioPopup';
import DeletePopup from '@Popups/DeletePopup';
import AddTrades from '@Popups/AddTrades';
import {mapGetters} from "vuex";

export default {
  name: 'MyPortfolio',

  components: {
    Layout,
    PortfolioPopup,
    DeletePopup,
    AddTrades,
  },

  data() {
    return {
      renderPage: false,
      actions: [
        {
          text: 'Edit',
          icon: 'pen',
          click: this.editPortfolio,
        },
        {
          text: 'Add XML',
          icon: 'plus',
          click: this.openAddTrades,
        },
        {
          text: 'Delete',
          type: 'danger',
          icon: 'trash',
          click: this.openDeletePopup,
        },
      ],
      currentPortfolio: undefined,
      portfoliosList: [],
    }
  },

  computed: {
    ...mapGetters({
      brokersAccountsList: 'brokers/brokersAccountsList',
      authFlag: 'auth/authFlag',
    }),
  },

  created() {
    if (!this.authFlag) {
      this.$store.dispatch('auth/getProfile');
      this.$store.dispatch('brokers/getBrokers');
    }
    this.getPortfolios();
  },

  methods: {
    customPopoverClick(e) {
      e.noRedirect=true;
    },
    getPortfolios() {
      this.errors = {};
      this.$api.getPortfolio().then((response) => {
        this.renderPage = true;
        this.portfoliosList = this.$_.cloneDeep(response.data.payload.list);
        if (!this.authFlag) {
          this.$store.commit('auth/setAuthFlag', true);
          if (this.portfoliosList.length === 0) {
            this.$nextTick(() => {
              this.addPortfolio();
            });
          }
          if (this.portfoliosList.length === 1) {
            this.$router.push(`/portfolios/${this.portfoliosList[0].id}`);
          }
        }
      });
    },

    getLink(e, id) {
      if (!e.noRedirect){
        this.$router.push(`/portfolios/${id}`);
      }
    },

    getImgUrl(val) {
      const logo = val ? this.brokersAccountsList.filter(item => item.id === val) : '';
      return logo.length > 0 ? logo[0].image : false;
    },

    accountsCount(accounts) {
      return accounts ? accounts.length : 0;
    },
    accountsPick(accounts) {
      return this.$_.map(accounts,'account_id').slice(0, 2);
    },

    openAddTrades(account) {
      this.currentPortfolio = account;
      this.$refs.addTrades.open(account);
    },
    openDeletePopup(account) {
      this.$refs.deletePopup.open(account);
    },

    addPortfolio() {
      this.currentPortfolio = undefined;
      this.$refs.portfolioPopup.open();
    },
    editPortfolio(account) {
      this.currentPortfolio = account;
      this.$refs.portfolioPopup.open();
    },

    deletePortfolio(id) {
      this.$api.deletePortfolio(id).then(() => {
        this.getPortfolios();
      });
    },
  },
};
</script>

<style lang="scss">
  .portfolio{
    cursor: pointer;
    &__info{
      position: relative;
      line-height: 24px;
    }
    &__time{
      font-size: 12px;
      font-weight: 500;
      color: var(--color-dark2);
    }
    &__title{
      font-size: 16px;
      font-weight: 700;
      color: var(--color-dark1);
    }
    &__summ{
      font-size: 20px;
      font-weight: 700;
      color: var(--color-main);
    }
    &__brokers{
      display: flex;
      align-items: center;
    }
  }
  .broker{
    padding: 4px 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-right: 4px;
    background-color: var(--color-dark5);
    &_popower{
      padding: 0;
      margin-bottom: 4px;
      margin-right: 0;
      background-color: transparent;
    }
    &:last-child{
      margin-right: 0;
    }
    &__ico{
      margin-right: 4px;
      width: 16px;
      height: 16px;
      img{
        width: 100%;
      }
    }
    &__ico-wrapper{
      display: flex;
      align-items: center;
      margin-right: 4px;
    }
    &__ico-wrapper &{
      &__ico{
        margin-right: 0;
        margin-left: -8px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
    &__name{
      font-size: 12px;
      font-weight: 500;
      color: var(--color-dark1);
    }
    &_popower &{
      &__name{
        color: var(--color-alternateTextColor);
      }
    }
    &__no-accounts &{
      &__name{
        color: var(--color-dark3);
      }
    }
    &__accounts-list.el-popover{
      border-radius: 11.5px;
      background-color: var(--color-dark1);
      border: none;
      box-shadow: none;
      padding: 8px;

      &.el-popper .popper__arrow {
        border-bottom-color: var(--color-dark1);
        &::after {
          border-bottom-color: var(--color-dark1);
        }
      }
    }
  }
</style>
