<template>
  <UiPopup
    popup-icon="key"
    popup-title="Change Password"
    popup-subtitle="To ensure your account is well protected, please use 8 or more characters with a mix of letters, numbers & symbols."
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <<UiInput
      slot="ui-popup__body"
      v-model="changeData.password"
      class="ui-m-xl-b"
      placeholder="********"
      label="Current Password"
      type="password"
      :error="errors.password"
    />
    <<UiInput
      slot="ui-popup__body"
      v-model="changeData.new_password"
      class="ui-m-xl-b"
      placeholder="********"
      label="New Password"
      type="password"
      :error="errors.new_password"
    />
    <<UiInput
      slot="ui-popup__body"
      v-model="changeData.new_password_confirmation"
      class="ui-m-xl-b"
      placeholder="********"
      label="Confirm New Password"
      type="password"
      :error="errors.new_password_confirmation"
    />
    <UiButton
      slot="ui-popup__footer"
      class="ui-12"
      :filled="true"
      size="big"
      substyle="fas"
      icon="key"
      @click="changePassword"
    >
      Change Password
    </UiButton>
  </UiPopup>
</template>

<script>
export default {
  name: 'ChangePassword',

  data() {
    return {
      isLoading: false,
      changeData: {
        password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      isVisible: false,
      errors: {},
    }
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    clear() {
      this.changeData = {
        password: '',
        new_password: '',
        new_password_confirmation: '',
      };
      this.errors = {};
    },

    changePassword() {
      this.isLoading = true;
      this.$api.putPassword(this.changeData).then(() => {
        this.clear();
        this.isVisible = false;
        this.$eventBus.$emit('notify-event', {type: 'success', text: 'Password Changed'});
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">

</style>
