var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"ui-input",class:[
    {'ui-input_big': _vm.size === 'big'},
    {'ui-input_small': _vm.size === 'small'},
    {'ui-input_focused': _vm.focused},
    {'ui-input_error': _vm.currentError},
    {'ui-input_search': _vm.isSearch},
    {'ui-input_disabled': _vm.disabled},
    {'ui-input_required': _vm.required} ]},[_c('span',{staticClass:"ui-input__label-text ui-m-sm-b"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('span',{staticClass:"ui-input__wrapper"},[_c('input',{ref:"input",staticClass:"ui-input__element",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"tabindex":_vm.tabindex,"type":_vm.currentType},domProps:{"value":_vm.currentValue},on:{"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}}),_vm._v(" "),(_vm.type === 'password')?_c('span',{staticClass:"ui-m-md-r ui-input__show-password",on:{"click":_vm.showPassword}},[_c('UiIcon',{attrs:{"size":16,"color":_vm.$theme.cp.dark3,"name":_vm.passwordIsShow ? 'eye-slash' : 'eye',"lib":"fa","substyle":"fas"}})],1):_vm._e()]),_vm._v(" "),(_vm.currentError)?_c('UiHelp',{staticClass:"ui-m-sm-t",attrs:{"is-error":true,"text":_vm.currentError}}):_vm._e(),_vm._v(" "),(_vm.help !== '')?_c('UiHelp',{staticClass:"ui-m-sm-t",attrs:{"text":_vm.help}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }