<template>
  <img
      class="logo"
      :class="{
        logo_big: size === 'big',
        logo_medium: size === 'medium'
      }"
      :src="logoUrl"
      alt="cso-logo">
</template>

<script>
import Logo from "@/images/logo.svg"

export default {
  name: 'UiLogo',

  props: {
    size: {
      type: String,
      default () {
        return 'medium';
      },
    },
  },

  data() {
    return {
      logoUrl: Logo,
    }
  },
};
</script>

<style lang="scss">
  .logo{
    &_medium{
      width: 140px;
    }
    &_big{
      width: 277px;
    }
  }
</style>