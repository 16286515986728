<template>
  <el-dropdown
      class="ui-group-actions"
      :class="{'ui-group-actions_active': dropdownActive}"
      placement="bottom-end"
      trigger="click"
      @click.native.stop
      @visible-change="dropdownChange"
  >
    <span
        class="el-dropdown-link transition"
        :class="[
        `ui-group-actions__${type}`
      ]"
    >
      <slot name="activator">
        <UiIcon
          :size="14"
          :color="dropdownActive ? $theme.cp.main : $theme.cp.dark3"
          name="ellipsis-v"
        />
      </slot>
    </span>
    <el-dropdown-menu
      slot="dropdown"
      class="ui-group-actions__dropdown"
      :visibleArrow="false"
    >
      <el-dropdown-item
        v-for="(item, key) in actions"
        :key="key"
        class="ui-group-actions__wrapper"
      >
        <router-link
            v-if="item.link"
            :to="item.link"
            class="ui-group-actions__item ui-d-flex ui-ai-center ui-jc-space-between ui-p-sm transition"
        >
          <div class="ui-group-actions__name">
            {{ item.text }}
          </div>
          <UiIcon
              :size="14"
              :color="item.type !== 'danger' ? $theme.cp.dark3 : $theme.cp.danger"
              :name="item.icon"
          />
        </router-link>
        <div
            v-if="item.click"
            class="ui-group-actions__item ui-d-flex ui-ai-center ui-jc-space-between ui-p-sm transition"
            :class="{
            'ui-group-actions__item_danger': item.type === 'danger'
          }"
            @click="item.click(initialData)"
        >
          <div class="ui-group-actions__name">
            {{ item.text }}
          </div>
          <UiIcon
              :size="14"
              :color="item.type !== 'danger' ? $theme.cp.dark3 : $theme.cp.danger"
              :name="item.icon"
          />
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

export default {
  name: 'UiGroupActions',

  props: {
    initialData: {
      type: Object,
      default () {
        return {};
      },
    },
    type: {
      type: String,
      default () {
        return 'ico';
      },
    },
    actions: {
      type: Array,
      required: true,
      default () {
        return [];
      },
    },
  },

  data () {
    return {
      dropdownActive: false,
    };
  },

  components: {},

  methods: {
    dropdownChange (val) {
      this.dropdownActive = val;
    },
  },
};
</script>

<style lang="scss">
.ui-group-actions {
  &__ico {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: var(--color-main10);
    }
  }

  &__dropdown{
    width: 182px;
    padding: 8px;
    margin: 0;
    border-radius: 8px;
    .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
      background-color: transparent;
    }
    &.el-popper{
      margin-top: 8px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    color: var(--color-dark1);
  }
  &__wrapper{
    padding: 0;
  }
  &__item {
    cursor: pointer;
    border-radius: 8px;
    color: var(--color-dark1);
    text-decoration: none;

    &:hover {
      background-color: var(--color-main10);
    }

    &_danger {
      .ui-group-actions__name {
        color: var(--color-danger);
      }

      &:hover {
        background-color: var(--color-danger10);
      }
    }
  }

  &__name {
    font-size: 14px;
    line-height: 1.29;
  }

  &_active & {
    &__ico {
      background-color: var(--color-main10);
    }
  }
}
</style>
