<template>
  <section>
    <Notify/>
    <transition name="fade" mode="out-in" appear>
      <the-header
        v-if="isShowHeader"
      />
    </transition>
    <transition name="fade" mode="out-in" appear>
      <router-view />
    </transition>
  </section>
</template>

<script>
import TheHeader from '@Components/TheHeader';
import {mapGetters} from 'vuex';
import Notify from "./components/Notify";

export default {
  name: 'App',

  components: {
    Notify,
    TheHeader,
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),

    isShowHeader() {
      return !this.$route.meta.hideHeader;
    },
  },

  created () {
    if (this.isAuthenticated){
      this.$store.dispatch('auth/getProfile');
      this.$store.dispatch('brokers/getBrokers');
    }
  },
}
</script>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
