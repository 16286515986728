<template>
  <UiPopup
    popup-icon="key"
    popup-title="Forgot Password"
    popup-subtitle="Enter your email below, you will receive an email with instructions on how to reset your password."
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <UiInput
      slot="ui-popup__body"
      v-model="email"
      class="ui-m-xl-b"
      placeholder="Your Email"
      label="Email"
      :error="errors.email"
    />
    <UiButton
      slot="ui-popup__footer"
      class="ui-12"
      :filled="true"
      size="big"
      substyle="fas"
      icon="check"
      @click="sendEmail"
    >
      Send Instructions
    </UiButton>
  </UiPopup>
</template>

<script>
export default {
  name: 'ForgotPassword',

  data() {
    return {
      isLoading: false,
      email: '',
      isVisible: false,
      errors: {},
    }
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    clear() {
      this.email = '';
      this.errors = {};
    },

    sendEmail() {
      this.isLoading = true;
      this.$api.forgotPassword(this.email).then(() => {
        this.$emit('sendEmailSuccess', this.email);
        this.clear();
        this.isVisible = false;
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">

</style>
