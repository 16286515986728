<template>
  <UiPopup
    popup-icon="trash"
    :popup-title="popupTitle"
    :popup-icon-color="$theme.cp.danger"
    :popup-subtitle="`Are you sure you want to delete <b>${info.name}</b>?`"
    :is-visible.sync="isVisible"
  >
    <div class="ui-g-w-md" slot="ui-popup__footer">
      <UiButton
        class="ui-g-md ui-6"
        size="big"
        substyle="fas"
        icon="times"
        @click="cancel"
      >
        Cancel
      </UiButton>
      <UiButton
        class="ui-g-md ui-6"
        type="danger"
        :filled="true"
        size="big"
        substyle="fas"
        icon="trash"
        @click="confirm"
      >
        Delete
      </UiButton>
    </div>
  </UiPopup>
</template>

<script>

export default {
  name: 'DeletePopup',

  data() {
    return {
      info: {},
      isVisible: false,
      errors: {},
    }
  },

  props: {
    popupTitle: {
      type: String,
      default() {
        return '';
      },
    },
  },

  methods: {
    open(info) {
      this.info = this.$_.cloneDeep(info);
      this.isVisible = true;
    },
    confirm() {
      this.$emit('confirm', this.info.id);
      this.isVisible = false;
    },
    cancel() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">

</style>
