export default {
  getBrokers() {
    return this.csoApi.get('brokers');
  },
  getBrokersAccounts() {
    return this.csoApi.get('brokers/accounts');
  },
  postBrokersAccounts(id, payload) {
    return this.csoApi.post(`brokers/${id}/accounts`, payload);
  },
  putBrokersAccounts(id, payload) {
    return this.csoApi.put(`brokers/accounts/${id}`, payload);
  },
  deleteBrokersAccount(id) {
    return this.csoApi.delete(`brokers/accounts/${id}`);
  },
  postTradesFile(id, payload) {
    return this.csoApi.post(`brokers/upload/${id}`, payload);
  },
};
