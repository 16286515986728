<template>
  <header class="header">
    <div class="header__wrapper">
      <router-link
        class="ui-d-flex ui-ai-center ui-m-xl-r"
        to="/my-portfolios"
      >
        <ui-logo size="medium"/>
      </router-link>
      <div class="header__menu">
        <div
          v-for="item in mainMenu"
          :key="item.key"
          class="header__menu-item ui-p-sm-r ui-p-sm-l"
        >
          <router-link
            :title="item.name"
            :to="item.link"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
      <UiGroupActions
        type="text"
        class="header__profile_position"
        :actions="actions"
      >
        <div slot="activator" class="header__user">
          <div class="header__user-icon ui-m-xs-r">
            <UiIcon
              :size="20"
              :color="$theme.cp.dark3"
              name="user-circle"
              lib="fa"
              substyle="fas"
            />
          </div>
          <div
            class="header__user-name ui-m-xs-r transition"
            :class="{'header__user-name_active': dropdownActive}"
          >
            {{ profile.name }}
          </div>
          <div
            class="header__user-arrow transition"
            :class="{'header__user-arrow_active': dropdownActive}"
          >
            <UiIcon
              :size="12"
              :color="$theme.cp.dark3"
              name="angle-down"
              lib="fa"
              substyle="fas"
            />
          </div>
        </div>
      </UiGroupActions>
    </div>
  </header>
</template>

<script>
import UiLogo from '@UiElements/UiLogo';
import { mapGetters } from 'vuex';

export default {
  name: 'TheHeader',
  components: {
    UiLogo,
  },
  data() {
    return {
      dropdownActive: false,
      actions: [
        {
          text: 'Settings',
          icon: 'cog',
          link: '/profile',
        },
        {
          text: 'Logout',
          type: 'danger',
          icon: 'sign-out',
          click: this.logout,
        },
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: 'auth/profile',
    }),

    mainMenu() {
      return [
        {
          name: 'My Portfolios',
          key: 'my-portfolios',
          link: '/portfolios/list',
        },
      ];
    },
  },
  mounted() {

  },
  methods: {
    dropdownChange(val) {
      this.dropdownActive = val;
    },

    logout() {
      this.$api.logout(this.loginData).then(() => {
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      });
    },
  },
};
</script>

<style lang="scss">
  .header{
    box-shadow: 0 1px 0 0 var(--color-dark5);
    background-color: var(--color-baseBackground);
    margin-bottom: 4px;

    &__wrapper{
      height: 64px;
      min-width: 1100px;
      padding: 0 32px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__menu{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      height: 100%;
    }
    &__menu-item{
      height: 100%;
      display: flex;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      a{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        color: var(--color-dark1);
        transition: all .3s ease 0s;
        &:hover{
          color: var(--color-main);
        }
      }

      &_active{
        a{
          color: var(--color-main);
        }
      }
    }
    &__user{
      display: flex;
      align-items: center;
      cursor: pointer;
      &-name{
        font-size: 14px;
        font-weight: 500;
        color: var(--color-dark1);
      }
      &-arrow{
        display: flex;
      }
    }
    .ui-group-actions_active{
      .header__user-name{
        color: var(--color-main);
      }
      .header__user-arrow{
        transform: rotateZ(-180deg);
      }
    }
  }
</style>
