import { palettes as cpDefault } from './themes/default.js';
import { palettes as cpDark } from './themes/dark.js';

export class ThemesService {
  default = 'default';
  cpList = {
    default: cpDefault,
    dark: cpDark,
  };
  cp = {};

  initTheme(theme) {
    Object.keys(this.cpList[theme]).forEach(color => {
      const name = `--color-${color}`;
      this.cp[color] = `var(${name})`;
      document.documentElement.style.setProperty(name, this.cpList[theme][color]);
    });
  }

  install(Vue, options) {
    this.initTheme(this.default);
    Vue.prototype.$theme = this;
  }
}

export default new ThemesService();