<template>
  <UiPopup
    popup-icon="lock"
    popup-title="Two-factor Authentication"
    :popup-subtitle="popupSubtitle"
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <div
      slot="ui-popup__body"
      class="tfa-popup"
    >
      <UiCodeInput
        :mode.sync="codeInputMode"
        :error="this.errors.code"
        @complete="checkTfa"
      />
    </div>
  </UiPopup>
</template>

<script>
import UiCodeInput from '@UiElements/UiCodeInput';

export default {
  name: 'TfaPopup',

  components: {
    UiCodeInput,
  },

  data() {
    return {
      isLoading: false,
      isVisible: false,
      codeInputMode: 'app_code',
      errors: {},
    }
  },

  computed: {
    popupSubtitle() {
      return this.codeInputMode === 'app_code'
          ? 'Provide your authentication code from <b>Google Authenticator</b> in the field below to complete action.'
          : 'Enter the <b>backup code</b> into the field below to complete action.'
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    clear() {
      this.errors = {};
    },

    checkTfa(code) {
      this.isLoading = true;
      this.$api.getRecoveryCodes({ code: code }).then((response) => {
        this.$emit('success', response.data.payload.codes);
        this.isVisible = false;
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">

</style>
