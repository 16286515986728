import app from '@/js/app';

export default {
  responseSuccessInterceptor(response) {
    return response;
  },
  responseErrorInterceptor(error) {
    const errorMessage = error.response.data.message;
    try {
      if (error.response) {
        switch (error.response.status) {
        case 401:
          if (error.response.data.code && error.response.data.code === 'TwoFactorRequired') {
            app.$store.commit('auth/reset2faStatus');
            app.$router.push('/2fa');
          } else {
            app.$store.dispatch('auth/logout');
          }
          break;
        case 422:
          console.log(errorMessage);
          break;
        default:
          if (error.response.status.toString()[0] === '5') {
            app.$noty.error(app.$t('validation.errors[2]'));
          }
          break;
        }
      }
    } catch (e) {

    }
    return Promise.reject(error.response);
  },
};
