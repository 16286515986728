<template>
  <Authorization>
    <div slot="authorization__title">Two-factor Authentication</div>
    <div v-html="subtitle" slot="authorization__subtitle" />
    <UiCodeInput
      :isBackup="true"
      :mode.sync="codeInputMode"
      :error="this.errors.code"
      @complete="checkTfa"
      @clear="clear"
    />
  </Authorization>
</template>

<script>
import Authorization from '@Components/Authorization';
import UiCodeInput from '@UiElements/UiCodeInput';

export default {
  name: 'Tfa',

  components: {
    Authorization,
    UiCodeInput,
  },

  data() {
    return {
      errors: {},
      codeInputMode: 'app_code',
    }
  },

  computed: {
    subtitle() {
      return this.codeInputMode === 'app_code'
          ? 'Provide your authentication code from <b>Google Authenticator</b> in the field below to complete action.'
          : 'Enter the <b>backup code</b> into the field below to complete action.'
    },
  },

  methods: {
    clear() {
      this.errors = {};
    },

    checkTfa(code) {
      const params = {
        code: code,
        is_recovery: this.codeInputMode === 'app_code' ? 0 : 1,
      }
      this.$api.postTfaChallenge(params).then(async () => {
        await this.$store.commit('auth/set2faStatus', 'success');
        this.$router.push('/my-portfolios');
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      });
    },
  },
};
</script>

<style lang="scss">

</style>