<template>
  <label
    class="ui-input"
    :class="[
      {'ui-input_big': size === 'big'},
      {'ui-input_small': size === 'small'},
      {'ui-input_focused': focused},
      {'ui-input_error': currentError},
      {'ui-input_search': isSearch},
      {'ui-input_disabled': disabled},
      {'ui-input_required': required},
    ]"
  >
    <span class="ui-input__label-text ui-m-sm-b">
      {{ label }}
    </span>
    <span class="ui-input__wrapper">
      <input
        :placeholder="placeholder"
        :disabled="disabled"
        :tabindex="tabindex"
        :type="currentType"
        :value="currentValue"
        ref="input"
        class="ui-input__element"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
      >
      <span
        v-if="type === 'password'"
        class="ui-m-md-r ui-input__show-password"
        @click="showPassword"
      >
        <UiIcon
          :size="16"
          :color="$theme.cp.dark3"
          :name="passwordIsShow ? 'eye-slash' : 'eye'"
          lib="fa"
          substyle="fas"
        />
      </span>
    </span>
    <UiHelp
      v-if="currentError"
      class="ui-m-sm-t"
      :is-error="true"
      :text="currentError"
    />
    <UiHelp
      v-if="help !== ''"
      class="ui-m-sm-t"
      :text="help"
    />
  </label>
</template>

<script>
export default {
  name: 'UiInput',
  props: {
    value: {
      type: [String, Number],
      default () {
        return '';
      },
    },
    size: {
      type: String,
      default () {
        return 'big';
      },
    },
    type: {
      type: String,
      default () {
        return 'text';
      },
    },
    label: {
      type: String,
      default () {
        return '';
      },
    },
    placeholder: {
      type: [String, Number],
      default () {
        return 'Search';
      },
    },
    disabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    help: {
      type: String,
      default() {
        return '';
      },
    },
    isSearch: {
      type: Boolean,
      default () {
        return false;
      },
    },
    tabindex: {
      type: Number,
      default () {
        return 1;
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    errorTooltip: {
      type: Boolean,
      default() {
        return false
      },
    },
    required: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      currentValue: '',
      passwordIsShow: false,
      focused: false,
      currentError: null,
    };
  },
  computed: {
    currentType() {
      return (this.type !== 'password' || (this.type === 'password' && !this.passwordIsShow)) ? this.type : 'text'
    },
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentValue = newValue === undefined || newValue === null ? '' : newValue.toString();
        }
      },
      deep: true,
    },
    error(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentError = this.$_.isArray(newValue) ? newValue[0] : newValue;
      }
    },
  },

  created() {
    this.currentValue = this.value;
    this.currentError = this.$_.isArray(this.error)
        ? this.error[0].message
        : this.error;
  },

  methods: {
    showPassword() {
      this.passwordIsShow = !this.passwordIsShow;
    },

    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    focus() {
      this.$refs.input.focus();
    },

    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    blur() {
      this.$refs.input.blur();
    },

    handleInput(event) {
      const { value } = event.target;
      this.currentValue = value;
      this.$emit('input', value);
    },
    handleChange(event) {
      const { value } = event.target;
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
  .ui-input{
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease 0s;

    &__wrapper{
      display: inline-flex;
      align-items: center;
      border: 1px solid var(--color-dark4);
      background-color: var(--color-baseBackground);
      transition: all .2s;
      border-radius: 8px;
    }
    &__element{
      font-family: Inter, sans-serif;
      display: flex;
      align-items: center;
      height: 100%;
      min-width: 10%;
      max-width: 100%;
      flex: 10 1 auto;
      border: none;
      outline: none;
      padding: 0;
      background: transparent;
    }
    &__label-text{
      font-size: 12px;
      font-weight: 600;
      color: var(--color-dark1);
    }

    &__show-password{
      cursor: pointer;
    }

    &_big &{
      &__wrapper input{
        padding: 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        color: var(--color-dark1);
      }
    }
    &_error &{
      &__wrapper{
        border-color: var(--color-danger);
        background-color: var(--color-danger10);
      }
    }
    &_focused & {
      &__wrapper {
        border-color: var(--color-main);
      }
    }
    &_disabled & {
      &__wrapper {
        background-color: var(--color-dark5);
        border-color: var(--color-dark5);
      }
    }
  }
</style>
