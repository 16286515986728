export default {
  register(payload) {
    return this.csoApi.post('auth/register', payload);
  },
  login(payload) {
    return this.csoApi.post('auth/login', payload);
  },
  logout() {
    return this.csoApi.post('auth/logout');
  },
  forgotPassword(value) {
    return this.csoApi.post('auth/forgot-password', { email: value });
  },
  resetPassword(payload) {
    return this.csoApi.post('auth/reset-password', payload);
  },
  postTfaChallenge(payload) {
    return this.csoApi.post('auth/two-factor-challenge', payload); // проверка 2fa или резервного кода
  },
};
