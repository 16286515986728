import _ from 'lodash';
import moment from 'moment';
import QS from 'qs';

export default (App) => {
  if (!App.prototype.$_) {
    Object.defineProperty(App.prototype, '$_', {
      value: _,
      writable: false,
    });
  }

  if (!App.prototype.$qs) {
    Object.defineProperty(App.prototype, '$qs', {
      value: QS,
      writable: false,
    });
  }

  if (!App.prototype.$moment) {
    Object.defineProperty(App.prototype, '$moment', {
      value: moment,
      writable: false,
    });
  }
};
