<template>
  <div class="table-card">
    <div class="table-card__header">
      <Breadcrumbs
        v-if="breadcrumbs.length"
        :items="breadcrumbs"
      />
      <div class="table-card__header-wrapper">
        <div class="table-card__header-left">
          <slot name="headerPrepend" />
        </div>
        <div class="table-card__header-center">
          <slot name="header" />
        </div>
        <div class="table-card__header-right">
          <slot name="headerAppend" />
        </div>
      </div>
    </div>
    <UiTable
      :data="data"
      :fields="fields"
      :isLoading="isLoading"
      lazy-loading
      element-loading-custom-class="data-loader-spinner"
    >
      <slot slot="empty" name="empty"/>
    </UiTable>
  </div>
</template>

<script>
import Breadcrumbs from '@Components/Breadcrumbs';
import UiTable from '@UiElements/UiTable';

export default {
  name: 'TableCard',

  components: {
    UiTable,
    Breadcrumbs,
  },

  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
  },

  computed: {

  },
};
</script>

<style lang="scss">
  .table-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__header{
      padding: 16px 32px;
      &-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      &-center{
        display: flex;
      }
    }
  }
</style>
