<template>
  <UiPopup
    popup-icon="plus"
    popup-title="Upload XML"
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <div slot="ui-popup__body" class="add-trades-content">
      <UiSelect
        class="ui-m-xl-b"
        :value="portfolioId"
        :list="portfolioList"
        :disabled="true"
        :select-ico="false"
        label="Portfolio"
      />
      <UiSelect
        class="ui-m-xl-b"
        :value.sync="brokerAccountId"
        :list="filteredBrokersAccountsList"
        label="Broker Account"
        placeholder="Select broker account…"
        :error="errors.accounts"
      />
      <div class="ui-select__label-text ui-m-sm-b">
        XML File
      </div>
      <el-upload
        ref="upload"
        class="upload"
        :class="{'upload_disabled': isFile}"
        drag
        action=""
        :http-request="postFile"
        :auto-upload="false"
        :file-list="fileList"
        :multiple="false"
        :limit="1"
        :on-change="checkFiles"
        accept=".xml"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drag and Drop or <em>Select File</em></div>
      </el-upload>
      <UiHelp
        v-if="this.errors.trades"
        class="ui-m-sm-t"
        :is-error="true"
        :text="this.errors.trades[0]"
      />
    </div>
    <UiButton
      slot="ui-popup__footer"
      class="ui-12 ui-m-lg-t"
      :filled="true"
      :disabled="!brokerAccountId || !isFile"
      size="big"
      substyle="fas"
      icon="plus"
      @click="save"
    >
      Upload
    </UiButton>
  </UiPopup>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'AddTrades',

  props: {
    initialData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoading: false,
      portfolioId: '',
      brokerAccountId: '',
      portfolioList: [],
      filteredBrokersAccountsList: [],
      fileList: [],
      file: {},
      isVisible: false,
      isFile: false,
      errors: {},
    }
  },

  computed: {
    ...mapGetters({
      brokersAccountsList: 'brokers/brokersAccountsList',
    }),
  },

  watch: {
    isVisible(val) {
      if (val) {
        this.portfolioId = this.initialData.id;
        this.portfolioList.push(this.initialData);
        this.$nextTick(() =>{
          this.isFile = this.$refs.upload && this.$refs.upload.uploadFiles > 0;
        })
        let account_ids = this.$_.map(this.initialData.accounts,'account_id');
        this.filteredBrokersAccountsList = this.brokersAccountsList.filter((broker) => {
          return account_ids.includes(broker.id);
        })
      }
    },
  },

  methods: {
    async open() {
      this.isVisible = true;
    },

    clear() {
      this.errors = {};
      this.portfolioId = '';
      this.brokerAccountId = '';
      this.portfolioList = [];
      this.filteredBrokersAccountsList = [];
    },

    checkFiles(file, fileList) {
      this.isFile = fileList.length > 0;
    },

    postFile(file) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('trades', file.file);
      this.$api.postTradesFile(this.brokerAccountId, formData).then(() => {
        this.$emit('confirm');
        this.isVisible = false;
        this.clear();
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },

    save() {
      this.$refs.upload.submit();
    },
  },
};
</script>

<style lang="scss">
  .upload{
    .el-upload{
      width: 100%;
      &-dragger{
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: var(--color-main);
        background-color: var(--color-main10);
        &:hover{
          border-color: var(--color-mainDeep);
        }

        .el-upload__text{
          font-size: 12px;
          font-weight: 500;
          line-height: 1.33;
          color: var(--color-dark3);
          em{
            color: var(--color-main);
          }
        }
      }
    }
    .el-icon-upload{
      display: none;
    }
    .el-upload-list__item-name{
      font-family: Inter, sans-serif;
    }
  }
</style>
