<template>
  <div
    class="ui-card"
    :class="[
      `ui-card_${indent}-indent`,
      `ui-card_${radius}-radius`,
      {'ui-card_shadow': shadow}
    ]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'UiCard',

  props: {
    shadow: {
      type: Boolean,
      default () {
        return false;
      },
    },
    indent: {
      type: String,
      default () {
        return 'big';
      },
    },
    radius: {
      type: String,
      default () {
        return 'medium';
      },
    },
  },
};
</script>

<style lang="scss">
  .ui-card{
    border: solid 1px var(--color-dark5);
    background-color: var(--color-baseBackground);
    &_big{
      &-indent{
        padding: 32px;
      }
    }
    &_medium{
      &-indent{
        padding: 16px;
      }
      &-radius{
        border-radius: 16px;
      }
    }
    &_small{
      &-indent{
        padding: 8px;
      }
      &-radius{
        border-radius: 8px;
      }
    }
    &_shadow{
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    }
  }
</style>