import UiCard from '@UiElements/UiCard';
import UiHelp from '@UiElements/UiHelp';
import UiActionIcon from '@UiElements/UiActionIcon';
import UiSelect from '@UiElements/UiSelect';
import UiButton from '@UiElements/UiButton';
import UiIcon from '@UiElements/UiIcon';
import UiInput from '@UiElements/UiInput';
import UiTitle from '@UiElements/UiTitle';
import UiSeparator from '@UiElements/UiSeparator';
import UiGroupActions from '@UiElements/UiGroupActions';
import UiPopup from '@UiElements/UiPopup';

export default (Vue) => {
  Vue.component('UiCard', UiCard);
  Vue.component('UiHelp', UiHelp);
  Vue.component('UiActionIcon', UiActionIcon);
  Vue.component('UiSelect', UiSelect);
  Vue.component('UiButton', UiButton);
  Vue.component('UiIcon', UiIcon);
  Vue.component('UiInput', UiInput);
  Vue.component('UiTitle', UiTitle);
  Vue.component('UiSeparator', UiSeparator);
  Vue.component('UiGroupActions', UiGroupActions);
  Vue.component('UiPopup', UiPopup);
};
