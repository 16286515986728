<template>
  <el-tooltip
    class="ui-action-icon"
    :open-delay="300"
    placement="top"
    :content="tooltip"
  >
    <slot />
  </el-tooltip>
</template>

<script>
export default {
  name: 'ActionIcon',

  props: {
    tooltip: {
      type: String,
      default() {
        return '';
      },
    },
  },

  computed: {

  },
};
</script>

<style lang="scss">
.ui-action-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  text-decoration: none;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .1);
  }
}
</style>
