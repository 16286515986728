export default {
  getProfile() {
    return this.csoApi.get('user/profile');
  },
  putProfile(payload) {
    return this.csoApi.put('user/profile', payload);
  },
  putPassword(payload) {
    return this.csoApi.put('user/password', payload);
  },
};