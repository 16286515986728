<template>
  <div class="authorization ui-d-flex ui-ai-center ui-jc-center ui-fd-column ui-fg-1">
    <div class="authorization__logo ui-m-xxl-b">
      <ui-logo size="big"/>
    </div>
    <UiCard
      class="authorization__card ui-d-flex ui-jc-center ui-fd-column"
      indent="big"
    >
      <div class="authorization__title ui-m-xl-b">
        <slot name="authorization__title"></slot>
      </div>
      <div class="authorization__subtitle ui-m-xl-b">
        <slot name="authorization__subtitle"></slot>
      </div>
      <slot />
    </UiCard>
</div>
</template>

<script>
import UiLogo from '@UiElements/UiLogo';

export default {
  name: 'Authorization',

  components: {
    UiLogo,
  },
};
</script>

<style lang="scss">
  @import "@/scss/mixins.scss";

  .authorization{
    &__card{
      width: 398px;
    }
    &__title{
      font-size: 20px;
      font-weight: 700;
      color: var(--color-dark1);
    }
    &__subtitle{
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      color: var(--color-dark2);
    }
    &__link{
      text-decoration: none;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: normal;
      color: var(--color-main);
      transition: all .2s;
      &:hover{
        color: var(--color-mainDeep);
      }
    }
  }
</style>