import _ from 'lodash';
import store from '@/js/store';

const getters = {
  brokersList: state => state.brokersList,
  brokersAccountsList: state => state.brokersAccountsList,
};

const mutations = {
  setBrokers(state, payload) {
    state.brokersList = _.cloneDeep(payload);
  },
  setBrokersAccounts(state, payload) {
    state.brokersAccountsList = _.cloneDeep(payload);
  },
};

const actions = {
  getBrokers({ commit, dispatch }) {
    store.$api.getBrokers().then((response) => {
      commit('setBrokers', response.data.payload.list);
      dispatch('getBrokersAccounts');
    });
  },
  getBrokersAccounts({ commit }) {
    store.$api.getBrokersAccounts().then((response) => {
      let brokerAccountsList = _.cloneDeep(response.data.payload.list)
      brokerAccountsList = brokerAccountsList.map((account) => ({
        broker_id: account.broker_id,
        id: account.id,
        name: account.name,
        broker_name: _.find(state.brokersList, broker => broker.id === account.broker_id).name,
        image: _.find(state.brokersList, broker => broker.id === account.broker_id).image
      }))
      commit('setBrokersAccounts', brokerAccountsList);
    });
  },
};

const state = {
  brokersList: [],
  brokersAccountsList: [],
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
