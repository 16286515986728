<template>
  <UiPopup
    popup-icon="envelope"
    popup-title="Check Your Email"
    :popup-subtitle="popupSubtitle"
    :is-visible.sync="isVisible"
  >
    <UiButton
      slot="ui-popup__footer"
      class="ui-12"
      :filled="true"
      size="big"
      substyle="fas"
      icon="check"
      @click="close"
    >
      Got It
    </UiButton>
  </UiPopup>
</template>

<script>
export default {
  name: 'CheckEmail',

  data() {
    return {
      email: '',
      isVisible: false,
    }
  },

  computed: {
    popupSubtitle() {
      return `An email has been sent to ${this.email} with instructions to reset your password.`
    },
  },

  methods: {
    open(email) {
      this.email = email;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">

</style>
