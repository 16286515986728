<template>
  <div class="ui-code-input">
    <div class="ui-code-input__wrapper ui-m-lg-b">
      <div class="ui-code-input__title ui-m-sm-b">{{ codeInputParams.title }}</div>
      <CodeInput
          :key="mode"
          class="ui-code-input__form"
          :autoFocus="autofocus"
          :type="codeInputParams.type"
          :fields="codeInputParams.fields"
          :field-width="codeInputParams.fieldWidth"
          :field-height="48"
          @complete="complete"
      />
      <UiHelp
        v-if="currentError"
        class="ui-m-sm-t"
        :is-error="true"
        :text="currentError"
      />
    </div>
    <div
      v-if="isBackup"
      class="ui-code-input__change-mode"
      @click="changeMode"
    >
      {{ codeInputParams.buttonText }}
    </div>
  </div>
</template>

<script>
import CodeInput from 'vue-verification-code-input';

export default {
  name: 'UiCodeInput',

  components: {
    CodeInput,
  },

  props: {
    mode: {
      type: String,
      default () {
        return 'app_code';
      },
    },
    isBackup: {
      type: Boolean,
      default () {
        return false;
      },
    },
    autofocus: {
      type: Boolean,
      default () {
        return true;
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      currentError: null,
    }
  },

  computed: {
    codeInputParams() {
      let params = {};
      if (this.mode === 'app_code') {
        params = {
          fields: 6,
          fieldWidth: 55,
          type: 'number',
          title: 'Code From App',
          buttonText: 'Enter Backup Code',
        };
      } else {
        params = {
          fields: 8,
          fieldWidth: 41,
          type: 'text',
          title: 'Backup Code',
          buttonText: 'Enter Code From App',
        };
      }
      return params
    },
  },

  watch: {
    error(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentError = this.$_.isArray(newValue) ? newValue[0] : newValue;
      }
    },
  },

  created() {
    this.currentError = this.$_.isArray(this.error) ? this.error[0] : this.error;
    if (this.autofocus) {
      this.$nextTick(() => {
        document.getElementsByClassName('react-code-input')[0].children[0].focus();
      });
    }
  },

  methods: {
    complete(code) {
      this.$emit('complete', code);
    },

    changeMode() {
      this.mode === 'app_code' ? this.$emit('update:mode', 'backup_code') : this.$emit('update:mode', 'app_code');
      this.$emit('clear');
      this.$nextTick(() => {
        document.getElementsByClassName('react-code-input')[0].children[0].focus();
      });
    },
  },
};
</script>

<style lang="scss">
.ui-code-input{
  text-align: center;
  &__title{
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-dark1);
  }
  &__form{
    .react-code-input>input{
      font-size: 25px;
      font-weight: 500;
      font-family: Inter, sans-serif;
      border: 1px solid var(--color-dark4);
      border-right: none;
      &:last-child{
        border-right: 1px solid var(--color-dark4);
      }
      &:focus {
        border: 1px solid var(--color-main);
        border-right: none;
        caret-color: #525461;
        +input{
          border-left: 1px solid var(--color-main);
        }
      }
      &:last-child:focus{
        border: 1px solid var(--color-main);
      }
    }
  }
  &__change-mode{
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: var(--color-main);
    cursor: pointer;
    display: inline-block;
    border-bottom: 1px dashed var(--color-main);
  }
}
</style>