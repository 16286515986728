import numeral from 'numeral';

export default (App) => {
  App.filter('formatDateDot', value => App.prototype.$moment(value).format('DD.MM.YYYY'));
  App.filter('formatDateTime', value => App.prototype.$moment(value).format('DD MMM YYYY HH:mm'));
  App.filter('formatDate', value => App.prototype.$moment(value).format('DD MMM YYYY'));
  App.filter('formatMoney', value => numeral(value).format('0,0.00'));
  App.filter('formatMoneyNoFrac', value => numeral(value).format('0,0'));
  App.filter('formatPercent', value => Math.abs(numeral(value).format('0.0')) + '%');
};
