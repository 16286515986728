<template>
  <div
    class="ui-select"
    :class="[
      {'ui-select_focused': focused},
      {'ui-input_error': currentError},
      {'ui-select_disabled': disabled},
    ]"
  >
    <span class="ui-select__label-text ui-m-sm-b">
      {{ label }}
    </span>
    <span class="ui-select__wrapper">
      <el-select
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :multiple="multiple"
        collapse-tags
        class="ui-select__element"
        popper-class="ui-select__dropdown"
        @input="$emit('update:value', $event )"
        @focus="focus"
        @blur="blur"
      >
        <template slot="empty">
          <div class="ui-select__no-data">No matching data</div>
        </template>
        <template slot="prefix">
          <img
            v-if="getImgUrl()"
            :src="getImgUrl()"
            class="prefix"
          >
        </template>
        <el-option
          v-for="item in list"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
           <span>{{ item.name }}</span>
        </el-option>
      </el-select>
    </span>
  </div>
</template>

<script>
export default {
  name: 'UiSelect',
  props: {
    value: {
      type: [String, Number, Array],
      default () {
        return '';
      },
    },
    list: {
      type: Array,
      default () {
        return [];
      },
    },
    label: {
      type: String,
      default () {
        return '';
      },
    },
    placeholder: {
      type: [String, Number],
      default () {
        return 'Search';
      },
    },
    disabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    errorTooltip: {
      type: Boolean,
      default() {
        return false
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      },
    },
    selectIco: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      focused: false,
      currentError: null,
      currentValue: '',
    };
  },
  computed: {

  },

  watch: {
    error(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentError = this.$_.isArray(newValue) ? newValue[0] : newValue;
      }
    },
  },

  created() {
    this.currentValue = this.value;
    this.currentError = this.$_.isArray(this.error)
      ? this.error[0].message
      : this.error;
  },

  methods: {
    getImgUrl() {
      if (this.multiple || !this.selectIco) {
        return false
      }
      const brokerLogo = this.value && this.list ? this.list.filter(item => item.id === this.value) : '';
      return brokerLogo ? brokerLogo[0].image : false;
    },
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
  },
};
</script>

<style lang="scss">
  .ui-select{
    display: flex;
    flex-direction: column;
    &__dropdown{
      margin: 0;
      border-radius: 16px;
      box-shadow: 0 0 8px 0 rgb(0 0 0 / 8%);
      background-color: var(--color-baseBackground);
      .el-popper{
        margin-top: 8px;
      }
      .popper__arrow{
        display: none;
      }
      .el-select-dropdown__list {
        padding: 8px;
        margin: 0;
      }
      .el-select-dropdown__item {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        padding: 8px;
        height: auto;
        border-radius: 8px;
        margin-bottom: 4px;
        &:last-child{
          margin-bottom: 0;
        }
        color: var(--color-dark1);
        &.hover,
        &:hover{
          background-color: var(--color-main10);
        }
      }

      &.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
        background-color: var(--color-main10);
        color: var(--color-dark1);
      }
    }
    &__label-text{
      font-size: 12px;
      font-weight: 600;
      color: var(--color-dark1);
    }
    &__wrapper{
      display: inline-flex;
      align-items: center;
      border: 1px solid var(--color-dark4);
      background-color: var(--color-dark6);
      transition: all .2s;
      border-radius: 8px;

      .el-input__inner{
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        padding-left: 16px;
        color: var(--color-dark1);
        border: none;
        border-radius: 8px;
        background-color: transparent;
      }
      .el-input--prefix{
        .el-input__inner{
          padding-left: 40px;
        }
      }
      .el-input__prefix{
        left: 16px;
        display: flex;
        align-items: center;
        img{
          width: 16px;
          height: 16px;
        }
      }
      .el-input.is-disabled .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        color: var(--color-dark1);
      }
    }
    &__element{
      display: flex;
      align-items: center;
      height: 100%;
      min-width: 10%;
      max-width: 100%;
      flex: 10 1 auto;
      border: none;
      outline: none;
      padding: 0;
      background: transparent;

      .el-input__inner{
        border: none;
        border-radius: 8px;
        background-color: transparent;
      }

      .el-tag{
        border-radius: 8px;
        border: solid 1px var(--color-dark4);
        background-color: var(--color-dark5);
        color: var(--color-dark1);
        padding: 4px 8px;
        font-weight: 500;

        &__close{
          right: 0;
          margin-left: 4px;
          color: var(--color-dark2) !important;
          font-family: "Font Awesome 6 Pro" !important;
          font-weight: 900;
          font-size: 18px;
          background-color: transparent !important;
          border: none;
          width: auto;
          height: auto;
          line-height: 19px;
          border-radius: 0;
          &:before{
            content: '\f057';
          }
        }
      }
    }
    &_focused & {
      &__wrapper {
        border-color: var(--color-main);
      }
    }
    &_error &{
      &__wrapper{
        border-color: var(--color-danger);
        background-color: var(--color-danger10);
      }
    }
    &_disabled & {
      &__wrapper {
        background-color: var(--color-dark5);
        border-color: var(--color-dark5);
      }
    }
    &__no-data{
      padding: 10px 0;
      margin: 0;
      text-align: center;
      font-weight: 600;
      color: var(--color-dark3);
      font-size: 12px;
    }
  }
</style>
