<template>
  <Authorization>
    <div slot="authorization__title">Create an account</div>
    <div slot="authorization__subtitle">
      Already have an account?
      <router-link class="authorization__link" to="/login">
        Log in
      </router-link>
    </div>
    <UiInput
      v-model="registerData.name"
      class="ui-m-xl-b"
      placeholder="Your name"
      label="Name"
      :error="errors.name"
    />
    <UiInput
      v-model="registerData.email"
      class="ui-m-xl-b"
      placeholder="Your Email"
      label="Email"
      :error="errors.email"
    />
    <UiInput
      v-model="registerData.password"
      class="ui-m-xl-b"
      placeholder="********"
      label="Password"
      type="password"
      :error="errors.password"
    />
    <UiInput
      v-model="registerData.password_confirmation"
      class="ui-m-xl-b"
      placeholder="********"
      label="Confirm password"
      type="password"
      help="Password should contain both letter and number, with minimum length of 8 characters"
    />
    <UiButton
      :filled="true"
      size="big"
      substyle="fas"
      icon="check"
      @click="register"
    >
      Create an Account
    </UiButton>
  </Authorization>
</template>

<script>
import Authorization from '@Components/Authorization';

export default {
  name: 'Join',

  components: {
    Authorization,
  },

  data() {
    return {
      errors: {},
      registerData: {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
      }
    }
  },

  methods: {
    register() {
      this.$store.commit('auth/setAuthFlag', false);
      this.$api.register(this.registerData).then(async (response) => {
        await this.$store.dispatch('auth/login', response.data.payload);
        this.$router.push('/my-portfolios');
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      });
    },
  },
};
</script>

<style lang="scss">

</style>
