<template>
  <div class="breadcrumbs">
    <div
      v-for="(item, index) in items"
      :key="item.link"
      class="breadcrumbs__item"
    >
      <template v-if="index < items.length - 1">
        <router-link
          class="breadcrumbs__link"
          :to="item.link"
        >
          {{ item.title }}
        </router-link>

        <span class="breadcrumbs__divider">></span>
      </template>

      <span
        v-else
        class="breadcrumbs__inactive-link"
      >
        <span class="title">{{ item.title }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  display: flex;
  margin-bottom: 12px;
  flex-grow: 1;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--color-dark3);

  &__link {
    text-decoration: none;
    color: var(--color-dark3);
  }

  &__divider {
    margin: 0 8px;
  }

  &__inactive-link {
    color: var(--color-dark1);
  }
}
</style>
