<template>
  <UiPopup
    popup-icon="lock"
    popup-title="Setup Two-Factor Authentication"
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <div
      slot="ui-popup__body"
      class="setup-tfa"
    >
      <div class="setup-tfa__step ui-m-lg-b">
        1. Open Google Authenticator and scan QR-code
      </div>
      <div class="setup-tfa__download">
        <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605" target="_blank" class="setup-tfa__download-item ui-m-sm-r">
          <img :src="appleImgUrl" alt="">
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" class="setup-tfa__download-item">
          <img :src="googleImgUrl" alt="">
        </a>
      </div>
      <div class="setup-tfa__qr ui-m-lg-b">
        <img width="205" height="205" :src="qrUrl" alt="">
      </div>
      <div class="setup-tfa__step ui-m-sm-b">
        2. Provide your authentication code from Google Authenticator in the field below to complete action.
      </div>
      <UiCodeInput
        :error="this.errors.code"
        @complete="enableTfa"
      />
    </div>
  </UiPopup>
</template>

<script>
import UiCodeInput from '@UiElements/UiCodeInput';
import AppleImg from "@/images/app/app-store.svg"
import GoogleImg from "@/images/app/google-play.svg"

export default {
  name: 'SetupTFA',

  components: {
    UiCodeInput,
  },

  data() {
    return {
      isLoading: false,
      appleImgUrl: AppleImg,
      googleImgUrl: GoogleImg,
      svg: '',
      isVisible: false,
      errors: {},
    }
  },

  computed: {
    qrUrl() {
      return `data:image/svg+xml;base64,${this.svg}`;
    }
  },

  watch: {
    isVisible: {
      handler(value) {
        if (value) {
          this.getQrUrl();
        }
      },
      immediate: true,
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    clear() {
      this.errors = {};
    },

    getQrUrl() {
      this.$api.postTfa().then((response) => {
        this.svg = response.data.payload.svg;
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      });
    },

    enableTfa(code) {
      this.isLoading = true;
      this.$api.putTfa(code).then((response) => {
        this.isVisible = false;
        this.$store.commit('auth/setUserInfo', response.data.payload.user);
        this.$emit('success', response.data.payload.codes);
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
    }
  },
};
</script>

<style lang="scss">
  .setup-tfa{
    &__step{
      font-size: 12px;
      font-weight: 500;
      line-height: 1.67;
      color: var(--color-dark2);
    }
    &__download{
      display: flex;
      align-items: center;
      justify-content: center;
      &-item{
        height: 40px;
      }
    }
    &__qr{
      text-align: center;
    }
  }
</style>
