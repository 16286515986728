<template>
  <UiPopup
    popup-icon="briefcase"
    :popup-title="`${ actionName } Portfolio`"
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <div slot="ui-popup__body" class="broker-account-content">
      <UiInput
        v-model="name"
        class="ui-m-xl-b"
        placeholder="Input Portfolio Name"
        label="Portfolio Name"
        :error="errors.name"
      />
      <UiSelect
        class="ui-m-xl-b"
        :value.sync="broker_ids"
        :list="brokersAccountsList"
        :multiple="true"
        label="Broker Account"
        placeholder="Select broker account…"
        :error="errors.accounts"
      />
    </div>
    <UiButton
      slot="ui-popup__footer"
      class="ui-12"
      :filled="true"
      size="big"
      substyle="fas"
      icon="check"
      @click="save"
    >
      {{ actionName }}
    </UiButton>
  </UiPopup>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'PortfolioPopup',

  props: {
    initialData: {
      type: Object,
      default() {
        return {
          name: '',
          broker_ids: [],
          id: '',
        };
      },
    },
  },

  data() {
    return {
      isLoading: false,
      name: '',
      id: '',
      broker_ids: [],
      isVisible: false,
      errors: {},
    }
  },

  computed: {
    ...mapGetters({
      brokersList: 'brokers/brokersList',
      brokersAccountsList: 'brokers/brokersAccountsList',
    }),
    actionName() {
      return !this.isEdit ? 'Create' : 'Edit';
    },
    isEdit() {
      return this.id !== '';
    }
  },

  watch: {
    isVisible(v) {
      if (v) {
        [
          this.name,
          this.id,
        ] = [
          this.initialData.name,
          this.initialData.id,
        ];
        this.broker_ids = this.$_.map(this.initialData.accounts,'account_id');
      }
    },
  },

  methods: {
    async open() {
      this.isVisible = true;
    },

    clear() {
      this.errors = {};
      this.name = '';
      this.broker_id = [];
      this.id = '';
    },
    putPortfolioAccounts(id, data) {
      this.$api.putPortfolioAccounts(id, data)
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() =>{
        this.isLoading = false;
        this.continue();
      });
    },

    continue() {
      this.clear();
      this.isVisible = false;
      this.$emit('confirm');
    },

    save() {
      this.isLoading = true;

      const data = {
        accounts: this.broker_ids
      };

      if (!this.isEdit) {
        this.$api.postPortfolio({name: this.name}).then((response) => {
          const id = response.data.payload.portfolio.id;
          this.putPortfolioAccounts(id, data);
        })
        .catch((error) => {
          this.isLoading = false;
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
      } else {
        this.$api.putPortfolio(this.id,{name: this.name}).then(() => {
          this.putPortfolioAccounts(this.id, data);
        })
        .catch((error) => {
          this.isLoading = false;
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
      }
    },
  },
};
</script>

<style lang="scss">

</style>
