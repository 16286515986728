<template>
  <Authorization>
    <ForgotPassword
      ref="forgotPassword"
      @sendEmailSuccess="openCheckPopup"
    ></ForgotPassword>
    <CheckEmail
      ref="checkEmail"
    ></CheckEmail>
    <div slot="authorization__title">Log In</div>
    <div slot="authorization__subtitle">
      New to CSO Portfolio?
      <router-link class="authorization__link"  to="/join">
        Create an Account
      </router-link>
    </div>
    <UiInput
      v-model="loginData.email"
      class="ui-m-xl-b"
      placeholder="Your Email"
      label="Email"
      :error="errors.email"
    />
    <UiInput
      v-model="loginData.password"
      class="ui-m-xl-b"
      placeholder="********"
      label="Password"
      type="password"
      :error="errors.password"
    />
    <UiButton
      class="ui-m-xl-b"
      :filled="true"
      size="big"
      substyle="fas"
      icon="sign-in"
      @click="login"
    >
      Log In
    </UiButton>
    <div
      class="forgot-password"
      @click="openForgotPassword"
    >
      Forgot Password?
    </div>
  </Authorization>
</template>

<script>
import Authorization from '@Components/Authorization';
import ForgotPassword from '@Popups/ForgotPassword';
import CheckEmail from '@Popups/CheckEmail';
import {mapGetters} from 'vuex';

export default {
  name: 'Login',

  components: {
    Authorization,
    ForgotPassword,
    CheckEmail
  },

  data() {
    return {
      errors: {},
      loginData: {
        email: '',
        password: '',
      }
    }
  },

  computed: {
    ...mapGetters({
      profile: 'auth/profile',
    }),
  },

  methods: {
    login() {
      this.$store.commit('auth/setAuthFlag', false);
      this.$api.login(this.loginData).then(async (response) => {
        await this.$store.dispatch('auth/login', response.data.payload);
        if (this.profile.two_factor) {
          this.$router.push('/2fa');
        } else {
          this.$store.commit('auth/set2faStatus', 'success');
          this.$router.push('/my-portfolios');
        }
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      });
    },
    openForgotPassword() {
      this.$refs.forgotPassword.open();
    },
    openCheckPopup(email) {
      this.$refs.checkEmail.open(email);
    }
  },
};
</script>

<style lang="scss">
  .forgot-password{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    cursor: pointer;
    color: var(--color-dark2);
  }
</style>
