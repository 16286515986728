<template>
  <Authorization>
    <div slot="authorization__title">Update your password</div>
    <div slot="authorization__subtitle">
      To ensure your account is well protected, please use 8 or more characters with a mix of letters, numbers & symbols.
    </div>
    <UiInput
      v-model="resetPasswordData.password"
      class="ui-m-xl-b"
      placeholder="********"
      label="New Password"
      type="password"
      :error="errors.password"
    />
    <UiInput
      v-model="resetPasswordData.password_confirmation"
      class="ui-m-xl-b"
      placeholder="********"
      label="Confirm New Password"
      type="password"
    />
    <UiInput
      :filled="true"
      size="big"
      substyle="fas"
      icon="key"
      @click="resetPassword"
    >
      Change Password
    </UiInput>
  </Authorization>
</template>

<script>
import Authorization from '@Components/Authorization';

export default {
  name: 'ResetPassword',

  components: {
    Authorization,
  },

  data() {
    return {
      errors: {},
      resetPasswordData: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      }
    }
  },

  created () {
    this.resetPasswordData.token = this.$route.query.token;
    this.resetPasswordData.email = this.$route.query.email;
  },

  methods: {
    resetPassword() {
      this.$api.resetPassword(this.resetPasswordData).then(() => {
        this.$router.push('/login');
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      });
    },
  },
};
</script>

<style lang="scss">

</style>