import Vue from 'vue';
import Router from 'vue-router';
import Login from '../views/Login';
import Join from '../views/Join';
import Tfa from '../views/Tfa';
import ResetPassword from '../views/ResetPassword';
import Profile from '../views/Profile';
import Portfolios from '../views/Portfolios';
import PortfoliosList from '../views/Portfolios/PortfoliosList';
import Assets from '../views/Portfolios/Assets';

Vue.use(Router);

const RouterInstance = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        type: 'notAuth',
        view: 'login',
        title: 'Login - CSO Portfolio',
        hideHeader: true,
      },
    },
    {
      path: '/join',
      name: 'join',
      component: Join,
      meta: {
        type: 'notAuth',
        view: 'join',
        title: 'Join - CSO Portfolio',
        hideHeader: true,
      },
    },
    {
      path: '/2fa',
      name: '2fa',
      component: Tfa,
      meta: {
        type: 'notAuth',
        view: '2fa',
        title: 'Two Factor Authentication - CSO Portfolio',
        hideHeader: true,
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        type: 'notAuth',
        view: 'reset-password',
        title: 'Reset Password - CSO Portfolio',
        hideHeader: true,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true,
        view: 'profile',
        title: 'Profile - CSO Portfolio'
      },
    },
    {
      path: '/portfolios',
      name: 'portfolios',
      component: Portfolios,
      meta: {
        view: 'portfolios',
        defaultLink: true,
        requiresAuth: true,
      },
      children: [
        {
          path: 'list',
          component: PortfoliosList,
          name: 'portfolios-list',
          meta: {
            view: 'portfolios-list',
            title: 'My Portfolios - CSO Portfolio'
          },
        },
        {
          path: ':id(\\d+)',
          component: Assets,
          name: 'assets',
          meta: {
            view: 'assets',
            title: 'Assets - CSO Portfolio'
          },
        },
        {
          path: '',
          redirect: 'list'
        },
        {
          path: '*',
          redirect: 'list'
        }
      ],
    },
    {
      path: '',
      redirect: '/portfolios/list'
    },
    {
      path: '*',
      redirect: '/portfolios/list'
    }
  ],
  mode: 'history',
});

export default RouterInstance;
