import _ from 'lodash';
import store from '@/js/store';

const getters = {
  token: state => state.significantActionsSeconds || '',
  authStatus: state => state.authStatus,
  status2fa: state => state.status2fa,
  isAuthenticated: state => state.authStatus === 'success' && state.status2fa === 'success',
  profile: state => state.profile,
  profileFlag: state => state.profileFlag,
  authFlag: state => state.authFlag,
};

const mutations = {
  setAuthToken(state, payload) {
    state.token = payload;
  },
  set2faStatus(state, payload) {
    localStorage.setItem('status2fa', payload);
    state.status2fa = payload;
  },
  reset2faStatus() {
    localStorage.removeItem('status2fa');
    state.status2fa = '';
  },
  setAuthStatus(state, payload) {
    state.authStatus = payload;
  },
  setProfileFlag(state, payload) {
    state.profileFlag = payload;
  },
  setAuthFlag(state, payload) {
    state.authFlag = payload;
  },
  setUserInfo(state, payload) {
    state.profile = _.cloneDeep(payload);
  },
};

const actions = {

  async login({ commit }, payload) {
    await commit('setAuthToken', payload.token);
    await commit('setAuthStatus', 'success');
    await commit('setUserInfo', payload.user);
    await localStorage.setItem('auth-token', state.token);
    store.$api.csoApi.defaults.headers.common.Authorization = `Bearer ${state.token}`;
  },

  logout({ commit }) {
    commit('setAuthToken', '');
    commit('setAuthStatus', '');
    commit('reset2faStatus');
    localStorage.removeItem('auth-token');
  },

  async getProfile({ commit }) {
    if (state.profileFlag === 0) {
      await commit('setProfileFlag', 1);
      await store.$api.getProfile().then((response) => {
        commit('setUserInfo', response.data.payload.user);
      })
      .finally(() => {
        commit('setProfileFlag', 0);
      });
    }
  },
};

const state = {
  profileFlag: 0,
  authFlag: true,
  token: localStorage.getItem('auth-token') || '',
  status2fa: localStorage.getItem('status2fa') || '',
  authStatus: localStorage.getItem('auth-token') ? 'success' : '',
  profile: {},
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
