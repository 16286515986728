import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Notifications from 'vue-notification'

import VueClipboard from 'vue-clipboard2';

export default (App) => {
  App.use(Element);
  App.use(VueClipboard);
  App.use(Notifications);
};
