<template>
  <div class="ui-title ui-m-xl-b">
    <div class="ui-title__content ui-d-flex ui-ai-center ui-jc-space-between">
      <div class="ui-title__text">
        {{ titleText }}
      </div>
      <slot name="ui-title__add"></slot>
    </div>
    <div
      v-if="text !==''"
      class="ui-title__subtext ui-m-sm-t"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',

  props: {
    titleText: {
      type: String,
      default () {
        return '';
      },
    },
    text: {
      type: String,
      default () {
        return '';
      },
    },
  },
};
</script>

<style lang="scss">
.ui-title{
  &__content{
    height: 30px;
  }
  &__text{
    font-size: 20px;
    font-weight: bold;
    color: var(--color-dark1);
  }
  &__subtext{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--color-dark2);
  }
}
</style>