import Vue from 'vue';
import Vuex from 'vuex';

// module
import auth from './modules/auth';
import brokers from './modules/brokers';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

const StoreInstance = new Vuex.Store({
  modules: {
    auth,
    brokers,
  },
  strict: debug,
});

export default StoreInstance;
