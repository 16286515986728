<template>
  <div class="view assets">
    <AddTrades
      ref="addTrades"
      :initial-data="currentPortfolio"
      @confirm="getAssets"
    />
    <TableCard
      :data="tableData"
      :fields="currentFields"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoadingTable"
    >
      <div slot="headerPrepend" class="assets__prepend">
        <div class="assets__type">
          <div
            class="assets__type-item"
            :class="{'assets__type-item_active': currentType === 'OPT'}"
            @click="currentType = 'OPT'"
          >
            Options
          </div>
          <div
            class="assets__type-item"
            :class="{'assets__type-item_active': currentType === 'STK'}"
            @click="currentType = 'STK'"
          >
            Main Assets
          </div>
        </div>
      </div>
      <div slot="headerAppend" class="assets__append">
        <UiButton
          slot="ui-title__add"
          :filled="true"
          size="medium"
          substyle="fas"
          icon="plus"
          @click="openAddTrades"
        >
          Add XML
        </UiButton>
      </div>
      <div class="assets__empty" slot="empty">
        <UiIcon
          :size="48"
          :color="$theme.cp.dark3"
          name="table"
          lib="fa"
          substyle="fas"
          class="ui-m-lg-b"
        />
        No avaliable data
        <UiButton
          slot="ui-title__add"
          :filled="true"
          size="medium"
          substyle="fas"
          icon="plus"
          class="ui-m-lg-t"
          @click="openAddTrades"
        >
          Add XML
        </UiButton>
      </div>
    </TableCard>
  </div>
</template>

<script>
import TableCard from '@Components/TableCard';
import AddTrades from '@Popups/AddTrades';

export default {
  name: 'Assets',

  components: {
    TableCard,
    AddTrades,
  },

  data() {
    return {
      id: '',
      currentType: 'OPT',
      isLoadingTable: false,

      tableData: [],
      optFields: [
        {
          name: 'symbol',
          label: 'Ticket',
          formatter: this.formatTicket,
        },
        {
          name: 'put_call',
          label: 'Type',
          align: 'center',
          headerAlign: 'center',
          width: this.calcWidth(40),
          formatter: this.formatType,
        },
        {
          name: 'quantity',
          label: 'Quantity',
          align: 'right',
          headerAlign: 'right',
          minWidth: this.calcWidth(50),
          formatter: this.formatQuantity,
        },
        {
          name: 'currency',
          label: 'Curr.',
          width: this.calcWidth(40),
          headerAlign: 'center',
          align: 'center'
        },
        {
          name: 'price_avg',
          label: 'Av. Price',
          align: 'right',
          headerAlign: 'right',
          minWidth: this.calcWidth(52),
          formatter: this.formatMoney,
        },
        {
          name: 'price',
          label: 'Price',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'strike',
          label: 'Strike',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'price_premium',
          label: 'Premium',
          align: 'right',
          headerAlign: 'right',
          minWidth: this.calcWidth(52),
          formatter: this.formatMoney,
        },
        {
          name: 'price_premium_act',
          label: 'Act. Premium',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
          minWidth: this.calcWidth(96),
        },
        {
          name: 'pr_value',
          label: 'Pr. Value',
          align: 'right',
          headerAlign: 'right',
          minWidth: this.calcWidth(53),
          formatter: this.formatPrValue,
        },
        {
          name: 'expiry_at',
          label: 'Expiration',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatExpiry,
          minWidth: this.calcWidth(80),
        },
        {
          name: 'div',
          label: 'DIV',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'call_div',
          label: 'Call DIV',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'upl',
          label: 'U P&L',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'value',
          label: 'Value',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: '',
          label: '',
          align: 'right',
          minWidth: this.calcWidth(22),
        },
      ],
      stkFields: [
        {
          name: 'symbol',
          label: 'Ticket',
          formatter: this.formatTicket,
        },
        {
          name: 'quantity',
          label: 'Quantity',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatQuantity,
        },
        {
          name: 'currency',
          label: 'Curr.',
          headerAlign: 'center',
          align: 'center',
        },
        {
          name: 'price_avg',
          label: 'Av. Price',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'price_regular_market',
          label: 'Price',
          align: 'right',
          headerAlign: 'right',
          minWidth: this.calcWidth(75),
          formatter: this.formatPrice,
        },
        {
          name: 'value',
          label: 'Value',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'div',
          label: 'DIV',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'div_sum',
          label: 'DIV Sum',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'upl',
          label: 'U P&L',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: 'mrk_value',
          label: 'Mrk. Value',
          align: 'right',
          headerAlign: 'right',
          formatter: this.formatMoney,
        },
        {
          name: '',
          label: '',
          align: 'right',
          minWidth: this.calcWidth(22),
        },
      ],

      portfoliosList: [],
    }
  },

  watch: {
    id() {
      this.getAssets();
    },
    currentType() {
      this.getAssets();
    },

    $route() {
      this.id = this.$route.params.id;
    }
  },

  computed: {
    currentFields() {
      return this.currentType === 'OPT' ? this.optFields : this.stkFields;
    },

    currentPortfolio() {
      const portfolio = this.portfoliosList ? this.$_.find(this.portfoliosList, ['id', +this.id]) : {};
      return portfolio ? portfolio : {};
    },

    breadcrumbs() {
      return [
        {
          title: 'My Portfolios',
          link: '/portfolios/list',
        },
        {
          title: this.currentPortfolio.name,
        },
      ];
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getPortfolios();

    if (!this.id) {
      this.$router.push('/portfolios/list');
    }
  },

  methods: {
    getAssets() {
      this.isLoadingTable = true;
      this.$api.getPortfolioAssets(this.id, {category: this.currentType}).then((response) => {
        this.tableData = this.$_.cloneDeep(response.data.payload.list);
        this.tableData.forEach(element => element.pr_value = element.quantity * element.price_premium);
        this.isLoadingTable = false;
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() => {
        this.isLoadingTable = false;
        this.isLoadingTable = false;
      });
    },

    getPortfolios() {
      this.$api.getPortfolio().then((response) => {
        this.portfoliosList = this.$_.cloneDeep(response.data.payload.list);
      });
    },

    formatMoney(row, column, cellValue, index) {
      return this.$options.filters.formatMoney(cellValue);
    },

    formatMoneyNoFrac(row, column, cellValue, index) {
      if (!cellValue) {
        return '';
      }
      return this.$options.filters.formatMoneyNoFrac(cellValue);
    },

    formatDate(row, column, cellValue, index) {
      return this.$options.filters.formatDate(cellValue * 1000);
    },

    formatDynamic(type, firstValue, secondValue) {
      const diff = this.$options.filters.formatMoney(firstValue - secondValue);
      const percent = this.$options.filters.formatPercent(diff/secondValue*100);
      const status = diff > 0 ? 'positive' : 'negative';
      let children = '';

      if (type === 'percent') {
        children = `${status === 'positive' ? '+' : ''}${diff} (${percent})`;
      }

      if (type === 'yield') {
        children = `Yield ${percent}`;
      }

      return this.$createElement('div', {
        class: ['assets__table-dynamic', `assets__table-dynamic_${status}`],
      }, children)
    },

    formatType(row, column, cellValue, index) {
      return this.$createElement(
        'div', {
          class: ['assets__table-type', 'assets__table-tag', `assets__table-tag_${cellValue}`],
        },
        cellValue,
      )
    },

    formatTicket(row, column, cellValue, index) {
      return this.$createElement('el-popover', {
          props: {
            placement: 'top-start',
            trigger: 'hover',
            popperClass: 'assets__table-ticket-popover',
            openDelay: 300,
            content: row.name,
          },
        }, [
          this.$createElement(
            'span',
            {
              slot: 'reference',
              class: 'assets__table-ticket'
            }, cellValue
          )
        ],
      );
    },

    formatPrice(row, column, cellValue, index) {
      const formatMoney = this.$createElement('div', {}, this.formatMoney(row, column, cellValue));
      return this.$createElement('div', {}, [formatMoney, this.formatDynamic('percent', cellValue, row.price_avg)]);
    },

    formatQuantity(row, column, cellValue, index) {
      return this.$createElement(
        'span', {
          class: 'assets__table-quantity',
        },
        this.formatMoneyNoFrac(row, column, cellValue),
      )
    },
    formatPrValue(row, column, cellValue, index) {
      return this.$createElement(
        'div', {},
        this.$options.filters.formatMoneyNoFrac(row.quantity * row.price_premium),
      )
    },

    formatExpiry(row, column, cellValue, index) {
      const currentDate = this.$moment(new Date());
      const endDate = this.$moment(this.formatDate(row, column, cellValue));
      const daysCount = Math.round(this.$moment.duration(endDate.diff(currentDate)).asDays());
      let daysCountStatus = 'assets__table-tag_close';
      if (daysCount > 30) {
        daysCountStatus = 'assets__table-tag_near'
      }
      if (daysCount > 60) {
        daysCountStatus = 'assets__table-tag_far'
      }

      const formatDate = this.$createElement('div', {}, this.formatDate(row, column, cellValue));
      const daysCountTag = this.$createElement('span', {
        class: ['assets__table-expiry-days', 'assets__table-tag', daysCountStatus],
      }, `${daysCount} Day(s)`);

      return this.$createElement('div', {}, [formatDate, daysCount > 0 ? daysCountTag : '']);
    },

    calcWidth(width) {
      /**
       * 32 is left and right paddings (16px)
       */
      return width + 32;
    },

    openAddTrades() {
      this.$refs.addTrades.open();
    },
  },
};
</script>

<style lang="scss">
.assets{
  height: 100%;
  &__empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
  }
  &__type{
    display: flex;
    &-item{
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 600;
      color: var(--color-dark2);
      border-radius: 8px;
      cursor: pointer;
      margin-right: 4px;
      transition: all .2s;
      &:hover{
        color: var(--color-main);
        background-color: var(--color-main10);
      }
      &_active{
        color: var(--color-main);
        background-color: var(--color-main10);
      }
    }
  }
  &__table{
    &-ticket{
      cursor: pointer;
      &-popover{
        &.el-popover{
          padding: 4px 8px;
          border-radius: 11.5px;
          background-color: var(--color-dark1);
          line-height: 1.33;
          color: var(--color-alternateTextColor);
          min-width: auto;
          &.el-popper .popper__arrow::after {
            border-top-color: var(--color-dark1);
          }
        }
      }
    }
    &-tag{
      padding: 0 4px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      text-align: center;
      color: var(--color-dark1);
      &_call{
        background-color: var(--color-main10);
      }
      &_put{
        background-color: var(--color-danger10);
      }
      &_close{
        color: var(--color-success);
        background-color: var(--color-success10);
      }
      &_near{
        color: var(--color-warning);
        background-color: var(--color-warning10);
      }
      &_far{
        color: var(--color-danger);
        background-color: var(--color-danger10);
      }
    }
    &-type{
      text-transform: uppercase;
    }
    &-quantity{
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      text-align: right;
      color: var(--color-main);
      padding-bottom: 2px;
      border-bottom: 1px solid var(--color-main50);
    }
    &-expiry-days{
      margin-top: 4px;
      display: inline-block;
    }
    &-dynamic{
      font-size: 12px;
      font-weight: 500;
      margin-top: 4px;
      &_positive{
        color: var(--color-success);
      }
      &_negative{
        color: var(--color-danger);
      }
    }
  }
}
</style>
