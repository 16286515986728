<template>
  <router-view />
</template>

<script>

export default {
  name: 'Portfolios',

  data() {
    return {

    }
  },
};
</script>
