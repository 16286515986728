<template>
  <transition name="fade" mode="out-in" appear>
    <div v-if="isVisible" class="ui-popup">
      <div class="ui-popup__overlay">
        <UiCard v-loading="isLoadingPopup"  class="ui-popup__window">
          <div
            class="ui-popup__close"
            @click="close"
          >
            <UiIcon
              v-if="popupIcon"
              :size="24"
              name="times"
              lib="fa"
              substyle="fas"
            />
          </div>
          <div class="ui-popup__header  ui-m-lg-b">
            <UiIcon
              v-if="popupIcon"
              class="ui-m-lg-b"
              :size="48"
              :color="popupIconColor"
              :name="popupIcon"
              lib="fa"
              substyle="fas"
            />
            <div
              v-if="popupTitle"
              class="ui-popup__title ui-m-lg-b"
            >
              {{ popupTitle }}
            </div>
            <div
              v-if="popupSubtitle"
              v-html="popupSubtitle"
              class="ui-popup__subtitle"
            />
          </div>
          <div class="ui-popup__body">
            <slot name="ui-popup__body"></slot>
          </div>
          <div class="ui-popup__footer">
            <slot name="ui-popup__footer"></slot>
          </div>
        </UiCard>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiPopup',

  props: {
    isVisible: {
      type: Boolean,
      default () {
        return false;
      },
    },
    popupIcon: {
      type: String,
      default () {
        return '';
      },
    },
    popupTitle: {
      type: String,
      default () {
        return '';
      },
    },
    popupSubtitle: {
      type: String,
      default () {
        return '';
      },
    },
    popupIconColor: {
      default () {
        return this.$theme.cp.main;
      },
    },
    isLoadingPopup: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },

  data() {
    return {

    };
  },

  methods: {
    close() {
      this.$emit('update:isVisible', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">

  .ui-popup{
    position: relative;
    z-index: 900;
    &__overlay{
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
    &__window{
      width: 398px;
      top: 10vh;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
    }
    &__title{
      font-size: 20px;
      font-weight: 700;
      color: var(--color-dark1);
      line-height: normal;
    }
    &__subtitle{
      font-size: 12px;
      font-weight: 500;
      line-height: 1.67;
      color: var(--color-dark2);
    }
    &__close{
      cursor: pointer;
      position: absolute;
      top: 32px;
      right: 32px;
      transition: all .3s ease 0s;
      color: var(--color-dark3);
      &:hover{
        color: var(--color-main);
      }
    }
  }
</style>
