<template>
  <UiPopup
    popup-icon="landmark"
    :popup-title="`${ actionName } Broker Account`"
    popup-subtitle="Input a special name which will help you to recognize source of data lately when you will have a various amount of Portfolios"
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <div slot="ui-popup__body" class="broker-account-content">
      <UiInput
        v-model="name"
        class="ui-m-xl-b"
        placeholder="Broker Account Name"
        label="Name"
        :error="errors.name"
      />
      <UiSelect
        class="ui-m-xl-b"
        :value.sync="broker_id"
        :list="brokersList"
        :disabled="isEdit"
        label="Broker"
        placeholder="Select Broker"
      />
    </div>
    <UiButton
      slot="ui-popup__footer"
      class="ui-12"
      :filled="true"
      :disabled="broker_id === ''"
      size="big"
      substyle="fas"
      icon="check"
      @click="save"
    >
      {{ actionName }} Account
    </UiButton>
  </UiPopup>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'BrokerAccount',

  props: {
    initialData: {
      type: Object,
      default() {
        return {
          name: '',
          broker_id: '',
          id: '',
        };
      },
    },
  },

  data() {
    return {
      isLoading: false,
      name: '',
      broker_id: '',
      id: '',
      isVisible: false,
      errors: {},
    }
  },

  computed: {
    ...mapGetters ({
      brokersList: 'brokers/brokersList',
    }),

    actionName() {
      return !this.isEdit ? 'Add' : 'Edit';
    },
    isEdit() {
      return this.id !== '';
    }
  },

  watch: {
    isVisible(v) {
      if (v) {
        [
          this.name,
          this.broker_id,
          this.id,
        ] = [
          this.initialData.name,
          this.initialData.broker_id,
          this.initialData.id,
        ];
      }
    },
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    clear() {
      this.errors = {};
      this.name = '';
      this.broker_id = '';
      this.id = '';
    },

    continue() {
      this.clear();
      this.isVisible = false;
      this.$emit('confirm');
    },

    save() {
      this.isLoading = true;

      const data = {
        name: this.name,
      };

      if (!this.isEdit) {
        this.$api.postBrokersAccounts(this.broker_id, data).then(() => {
          this.continue();
          this.$eventBus.$emit('notify-event', {type: 'success', text: 'Broker Account Added'});
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
      } else {
        this.$api.putBrokersAccounts(this.id, data).then(() => {
          this.continue();
          this.$eventBus.$emit('notify-event', {type: 'success', text: 'Broker Account Edited'});
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">

</style>
