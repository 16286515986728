<template>
  <div
    class="ui-button"
    :class="[
      `ui-button_size-${size}`,
      `ui-button_type-${type}`,
      {
        'ui-button_filled': filled,
        'ui-button_disabled': disabled
      },
    ]"
    @click="onButtonClick"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
      :substyle="substyle"
      :color="getIconColor"
      :size="getIconSize"
      class="ui-m-sm-r"
    />
    <span class="ui-button__text">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    size: {
      type: String,
      default () {
        return 'big';
      },
    },
    disabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    filled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    type: {
      type: String,
      default () {
        return 'default';
      },
    },
    icon: {
      type: String,
      default() {
        return ''
      },
    },
    substyle: {
      type: String,
      default() {
        return 'fas'
      },
    },
    iconSize: {
      type: [String, Number],
      default: '',
    },
    iconColor: {
      type: String,
      default: '#58667e',
    },
  },

  computed: {
    getIconSize() {
      if (this.iconSize) {
        return this.iconSize;
      }
      switch (this.size) {
        case 'medium':
          return 12;
        case 'big':
          return 14;
        default:
          return 12;
      }
    },
    getIconColor() {
      if (this.filled) {
        return this.$theme.cp.alternateTextColor;
      }
      return this.$theme.cp.dark2;
    },
  },
  methods: {
    onButtonClick() {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  },
};
</script>

<style lang="scss">
  @import "@/scss/mixins.scss";

  .ui-button{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease 0s;
    user-select: none;
    white-space: nowrap;
    border-radius: 8px;

    &__text{
      line-height: 1;
    }

    &_size{
      &-big{
        padding: 16px;
        font-size: 14px;
        font-weight: 600;
      }
      &-medium{
        padding: 8px 16px;
        font-size: 12px;
        font-weight: 500;
      }
    }
    &_type{
      &-default{
        border: solid 1px var(--color-dark4);
        background-color: var(--color-baseBackground);
        color: var(--color-dark1);


        &.ui-button_filled {
          border: solid 1px var(--color-main);
          background-color: var(--color-main);
          color: var(--color-alternateTextColor);
        }

        &:not(.ui-button_disabled) {
          @at-root #{selector-append(&)} {
            @include hover {
              background-color: var(--color-dark5);
            }
          }
          @at-root #{selector-append(& + '.ui-button_filled')} {
            @include hover {
              border-color: var(--color-mainDeep);
              background-color: var(--color-mainDeep);
            }
          }
        }
      }
      &-danger{
        border: solid 1px var(--color-danger);
        background-color: var(--color-baseBackground);
        color: var(--color-dark1);

        &.ui-button_filled {
          background-color: var(--color-danger);
          color: var(--color-alternateTextColor);
        }

        &:not(.ui-button_disabled) {
          @at-root #{selector-append(&)} {
            @include hover {
              background-color: var(--color-danger10);
            }
          }
          @at-root #{selector-append(& + '.ui-button_filled')} {
            @include hover {
              border-color: var(--color-dangerDeep);
              background-color: var(--color-dangerDeep);
            }
          }
        }
      }
    }
    &_disabled{
      cursor: not-allowed;
      filter: grayscale(100%);
      border-color: var(--color-dark4);
      opacity: .5;
    }
  }
</style>
