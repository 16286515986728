<template>
  <div
    :style="{ top: '-100px' }"
    class="lazy-loader"
  >
    <content-loader
      :height="400"
      :speed="2"
      :width="990"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <rect
        height="30"
        rx="4"
        ry="4"
        width="930"
        x="29.69"
        y="75"
      />
      <rect
        height="15"
        rx="4"
        ry="4"
        width="930"
        x="29.69"
        y="110"
      />
      <rect
        height="15"
        rx="4"
        ry="4"
        width="930"
        x="29.69"
        y="135"
      />
      <rect
        height="15"
        rx="4"
        ry="4"
        width="930"
        x="29.69"
        y="160"
      />
      <rect
        height="15"
        rx="4"
        ry="4"
        width="930"
        x="29.69"
        y="185"
      />
      <rect
        height="15"
        rx="4"
        ry="4"
        width="930"
        x="29.69"
        y="210"
      />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'LazyLoadingTable',
  components: {
    ContentLoader,
  },
};
</script>

<style lang="scss">
.lazy-loader{
  position: relative;
}
</style>
