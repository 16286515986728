export default {
  postTfa() {
    return this.csoApi.post('user/two-factor-authentication'); // включаем, возвращает QR
  },
  putTfa(value) {
    return this.csoApi.put('user/two-factor-authentication', { code: value }); // подтверждаем включение вводом кода, при успехе получаем резервные
    // кооды
  },
  deleteTfa(params) {
    return this.csoApi.delete('user/two-factor-authentication', { params }); //
  },
  getRecoveryCodes(params) {
    return this.csoApi.get('user/two-factor-recovery-codes', { params }); // список резервных кодов
  },
  postRecoveryCodes() {
    return this.csoApi.post('user/two-factor-recovery-codes'); // генерация резервных кодов
  },
};