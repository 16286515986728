<template>
  <div>
    <el-table
      v-show="!isLoading"
      class="ui-table"
      ref="dataTable"
      :height="tableHeight"
      :data="data"
      show-summary
      :summary-method="getSummaries"
    >
      <slot slot="empty" name="empty">No avaliable data</slot>
      <el-table-column
        v-for="(field, id) in fields"
        :key="id+field.name"
        :column-key="field.name"
        :fixed="field.fixed || false"
        :align="field.align || 'left'"
        :formatter="field.formatter"
        :header-align="field.headerAlign || 'left'"
        :label="field.label"
        :min-width="field.minWidth || 'auto'"
        :prop="field.name"
        :width="field.width || 'auto'"
      >
      </el-table-column>
    </el-table>
    <LazyLoadingTable v-if="isLoading"/>
  </div>
</template>

<script>
import LazyLoadingTable from '@Components/LazyLoadingTable';

export default {
  name: 'UiTable',

  components: {
    LazyLoadingTable,
  },

  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      tableHeight: 0,
      key: 0,
    }
  },

  computed: {

  },

  watch: {
    data: {
      handler() {
        this.key = this.key + 1;
      },
      immediate: true,
    },
  },

  mounted() {
    window.addEventListener('resize', this.debouncedTableHeight);
  },

  created() {
    this.debouncedTableHeight = this.$_.debounce(
      this.computeTableHeight.bind(this),
      300,
    );
  },

  activated() {
    this.debouncedTableHeight();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedTableHeight);
  },

  updated() {
    this.debouncedTableHeight();
  },

  methods: {
    computeTableHeight() {
      /**
       * 64 is header height
       */
      this.tableHeight = window.innerHeight - this.$el.offsetTop - 64;
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if(column.property === 'value' || column.property === 'pr_value') {
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              let value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index] !== '' && this.$options.filters.formatMoney(sums[index]);
          } else {
            sums[index] = '';
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    }
  },
};
</script>

<style lang="scss">
  .ui-table {
    &.el-table{
      thead{
        tr {
          height: auto;
        }
      }
      tr {
        height: 68px;
        &:nth-child(even){
          background-color: var(--color-dark6);
        }
      }
      .el-table__footer-wrapper{
        tr{
          height: auto;
        }
        tbody td.el-table__cell{
          background-color: white;
          padding: 10px 0 12px;
        }
        .cell{
          font-weight: 700;
        }
      }
      th.el-table__cell{
        padding: 12px 0;
        &>.cell{
          padding: 0 16px;
          font-size: 12px;
          font-weight: 600;
          color: var(--color-dark1);
        }
      }
      .el-table__cell{
        padding: 8px 0;
        vertical-align: top;
      }
      .cell{
        padding: 0 16px;
        font-size: 13px;
        font-weight: 500;
        color: var(--color-dark1);
        line-height: normal;
      }
      td.el-table__cell,
      th.el-table__cell.is-leaf{
        border-bottom: none;
      }
      .el-table__header{
        border-radius: 8px 8px 0 0;
        border: solid 1px var(--color-dark5);
        overflow: hidden;
      }
      &--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
        background-color: var(--color-tableRowHover);
      }
    }
    .el-table__empty-text{
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: var(--color-dark2);
    }
  }
</style>
