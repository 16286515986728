const palettes = {
  main: '#3861fb',
  danger: '#EA3943',
  success: '#0DB173',
  dark1: '#222531',
  dark2: '#58667E',
  dark3: '#A6B0C3',
  dark4: '#CFD6E4',
  dark5: '#EFF2F5',
  dark6: '#FBFCFE',
}
const palettesAlpha = {
  main10: palettes.main + '1a',
  danger10: palettes.danger + '1a',
  success10: palettes.success + '1a',
}

module.exports = { palettes: {
    ...palettes,
    ...palettesAlpha
  }
}