<template>
  <UiPopup
    popup-icon="lock"
    popup-title="Disable Two-Factor Authentication"
    :is-visible.sync="isVisible"
    :is-loading-popup="isLoading"
    @close="clear"
  >
    <div
      slot="ui-popup__body"
      class="setup-tfa"
    >
      <UiInput
        v-model="disableData.password"
        class="ui-m-xl-b"
        placeholder="********"
        label="Password"
        type="password"
        :error="errors.password"
      />
      <UiCodeInput
        :autofocus="false"
        :error="this.errors.code"
        @complete="inputCode"
      />
    </div>
    <UiButton
      slot="ui-popup__footer"
      class="ui-12 ui-m-lg-t"
      :filled="true"
      size="big"
      substyle="fas"
      icon="check"
      @click="disableTfa"
    >
      Disable 2FA
    </UiButton>
  </UiPopup>
</template>

<script>
import UiCodeInput from '@UiElements/UiCodeInput';

export default {
  name: 'DisabledTFA',

  components: {
    UiCodeInput,
  },

  data() {
    return {
      isLoading: false,
      isVisible: false,
      disableData: {
        password: '',
        code: '',
      },
      errors: {},
    }
  },

  methods: {
    open() {
      this.isVisible = true;
    },

    clear() {
      this.errors = {};
    },

    inputCode(code) {
      this.disableData.code = code;
    },

    disableTfa() {
      this.isLoading = true;
      this.$api.deleteTfa(this.disableData).then((response) => {
        this.$store.commit('auth/setUserInfo', response.data.payload.user)
        this.isVisible = false;
        this.$eventBus.$emit('notify-event', {type: 'success', text: 'Two-factor Authentication Disabled'});
      })
      .catch((error) => {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
    }
  },
};
</script>

<style lang="scss">
  .setup-tfa{
    &__step{
      font-size: 12px;
      font-weight: 500;
      line-height: 1.67;
      color: var(--color-dark2);
    }
    &__download{
      display: flex;
      align-items: center;
      justify-content: center;
      &-item{
        height: 40px;
      }
    }
    &__qr{
      text-align: center;
    }
  }
</style>
