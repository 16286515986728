<template>
  <div
    class="ui-help"
    :class="{'ui-help_error': isError}"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'UiHelp',

  props: {
    text: {
      type: String,
      default () {
        return '';
      },
    },
    isError: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },

  computed: {

  },
};
</script>

<style lang="scss">
  .ui-help {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--color-dark2);
    text-align: left;
    &_error{
      color: var(--color-danger);
    }
  }
</style>
