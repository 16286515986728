import router from '../router';
import store from '../store';

const DEFAULT_ROUTE = '/my-portfolios';


export default () => {
  router.beforeEach((to, from, next) => {
    let nextPath = null;
    if (to.matched.some(record => record.meta.type === 'notAuth')) {
      if (store.getters['auth/isAuthenticated']) {
        nextPath = DEFAULT_ROUTE;
      }
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters['auth/isAuthenticated']) {
        nextPath = '/login';
      }
    }

    if (nextPath) {
      next(nextPath);
    } else {
      next();
    }


  });
  router.afterEach((to, from) => {
    document.title = to.meta.title;
  });
};
